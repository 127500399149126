import { Component, Input } from '@angular/core';
import { Slide } from '@libs/models';

@Component({
    selector: 'app-content-preview',
    templateUrl: './content-preview.component.html',
    styleUrls: ['./content-preview.component.scss'],
})
export class ContentPreviewComponent {
    @Input()
    slide: Slide;
}
