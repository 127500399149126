<div class="settings">
    <div mat-dialog-title>
        {{'navbar.settings-modal.general.title' | translate}}
    </div>

    <mat-dialog-content>
        <section class="settings__section">
            <div class="settings__column settings__column--big">
                <p class="settings__title mat-h3">
                    {{ 'navbar.settings-modal.general.video.title' | translate
                    }}
                </p>

                <mat-radio-group
                    class="settings__video-radio"
                    [ngModel]="isDefaultMode$ | async"
                    (ngModelChange)="setVideoEnabled($event)"
                >
                    <mat-radio-button [value]="true">
                        {{ 'navbar.settings-modal.general.video.platform' |
                        translate }}
                    </mat-radio-button>

                    <mat-radio-button [value]="false">
                        {{ 'navbar.settings-modal.general.video.disable' |
                        translate }}
                    </mat-radio-button>
                </mat-radio-group>

                <div class="mat-body-2 settings__description-text">
                    {{ 'navbar.settings-modal.general.video.text' | translate }}
                </div>
            </div>

            <div class="settings__column">
                <p class="settings__title mat-h3">
                    {{ 'navbar.settings.language' | translate }}
                </p>

                <mat-radio-group
                    class="settings__language-radio"
                    [ngModel]="currentLang"
                    (ngModelChange)="setLang($event)"
                >
                    <mat-radio-button value="en">EN</mat-radio-button>
                    <mat-radio-button value="de">DE</mat-radio-button>
                    <mat-radio-button value="es">ES</mat-radio-button>
                </mat-radio-group>
            </div>
        </section>

        <section class="settings__section">
            <div class="settings__column">
                <p class="settings__title mat-h3">
                    {{ 'navbar.settings-modal.general.reload.title' | translate
                    }}
                </p>

                <p class="mat-body-2 settings__description-text">
                    {{ 'navbar.settings-modal.general.reload.text' | translate
                    }}
                </p>

                <button
                    mat-stroked-button
                    color="warn"
                    type="button"
                    (click)="reloadRoom()"
                >
                    {{ 'navbar.settings-modal.general.reload.button' | translate
                    }}
                </button>
            </div>
        </section>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button
            mat-flat-button
            color="primary"
            [mat-dialog-close]="true"
            cdkFocusInitial
        >
            {{ 'navbar.settings-modal.done' | translate }}
        </button>
    </mat-dialog-actions>
</div>
