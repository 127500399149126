import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    ContentElementComponent,
    ImageElementStyle,
} from '@app/shared-modules/content-view/models';
import { ImageElementContext } from '@app/shared-modules/content-view/models/image-element-context.interface';
import { ContentElement } from '@libs/models';

@Component({
    selector: 'app-image-element',
    templateUrl: './image-element.component.html',
    styleUrls: ['./image-element.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageElementComponent implements ContentElementComponent {
    @Input() set element(element: ContentElement<ImageElementContext>) {
        if (!element) {
            throw new Error('[ImageElementComponent] Image is missed');
        }

        this.context = element.context;

        switch (element.context.style) {
            case ImageElementStyle.SlideImage:
                this.class = 'image-element--slide-image';
                break;
            case ImageElementStyle.TitleImage:
                this.class = 'image-element--title-image';
                break;
            default:
                this.class = null;
        }
    }

    context: ImageElementContext;

    class: string;
}
