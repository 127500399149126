<button
    mat-flat-button
    *ngIf="isStarted; else start"
    (click)="onStop()"
    color="primary"
>
    Stop
</button>

<ng-template #start>
    <button mat-flat-button (click)="onStart()" color="primary">Start</button>
</ng-template>
