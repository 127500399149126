<div class="timeline">
    <div class="timeline__content">
        <div
            *ngFor="let point of points; let i = index"
            class="timeline__point"
            [ngClass]="{ 'timeline__point--current': i === currentIndex }"
        >
            <p class="timeline__point-header">{{ point.title }}</p>

            <p *ngIf="point.text">{{ point.text }}</p>
        </div>
    </div>
</div>
