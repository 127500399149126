<div class="aspect-card-element">
    <div class="aspect-card-element__header">
        {{ element.context.title }}
    </div>

    <ng-container *ngIf="element.context.index > 0; else bulletPoint">
        <app-index [index]="element.context.index"></app-index>
    </ng-container>
</div>

<ng-template #bulletPoint>
    <app-bullet-point [style]="element.context.style"></app-bullet-point>
</ng-template>
