import { Injectable } from '@angular/core';
import { ContentViewServicesModule } from '@app/shared-modules/content-view/content-view-services.module';

import { Observable, of, Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: ContentViewServicesModule,
})
export class AudioHelperService {
    protected events = [
        'ended',
        'error',
        'play',
        'playing',
        'pause',
        'timeupdate',
        'canplay',
        'loadedmetadata',
        'loadstart',
    ];

    private stop$ = new Subject();

    private audioObj = new Audio();

    playStream(url: string): Observable<any> {
        return this.streamObservable(url).pipe(takeUntil(this.stop$));
    }

    play(): void {
        if (!this.audioObj.paused) {
            return;
        }
        this.audioObj.play();
    }

    pause(): void {
        this.audioObj.pause();
    }

    stop(): void {
        this.stop$.next(null);
    }

    seekTo(seconds: number): void {
        this.audioObj.currentTime = seconds;
    }

    formatTime(seconds: number): string {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = +seconds.toFixed(0) % 60;
        // eslint-disable-next-line no-nested-ternary
        return [h, m > 9 ? m : h ? `0${m}` : m || '0', s > 9 ? s : `0${s}`]
            .filter((a) => a)
            .join(':');
    }

    private streamObservable(url: string): Observable<any> {
        const addEvents = (obj, events, handler) => {
            events.forEach((event) => {
                obj.addEventListener(event, handler);
            });
        };

        const removeEvents = (obj, events, handler) => {
            events.forEach((event) => {
                obj.removeEventListener(event, handler);
            });
        };

        return of((observer) => {
            // Play audio
            this.audioObj.src = url;
            this.audioObj.load();
            // this.audioObj.play();

            // Media Events
            const handler = (event) => observer.next(event);
            addEvents(this.audioObj, this.events, handler);

            return () => {
                // Stop Playing
                this.audioObj.pause();
                this.audioObj.currentTime = 0;

                // Remove EventListeners
                removeEvents(this.audioObj, this.events, handler);
            };
        });
    }
}
