<div class="timepicker__header">
    <button mat-icon-button (click)="slidePrev()">
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>

    <span>{{ label }}</span>

    <button mat-icon-button (click)="slideNext()">
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
</div>

<div class="carousel mat-body-2">
    <section class="carousel-wrapper">
        <div class="carousel-inner">
            <app-timepicker-slide
                class="item-carousel"
                *ngFor="let item of ranges"
            >
                <ul class="timepicker-list">
                    <li
                        class="timepicker-item available"
                        *ngFor="let time of item.times; let i = index"
                        [ngClass]="{
                            'is-active': selectedTimeIndex === i
                        }"
                        (click)="selectTime(i, time)"
                    >
                        {{ time }}
                    </li>
                </ul>
            </app-timepicker-slide>
        </div>
    </section>
</div>
