import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErrorType } from '@app/error/models';
import { Observable, of, throwError } from 'rxjs';

import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '@app/core/services/error.service';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    api: string;

    constructor(
        private http: HttpClient,
        private readonly errorService: ErrorService
    ) {
        this.api = environment.api;
    }

    get<T = any>(
        path: string,
        options: { params?: HttpParams } = {}
    ): Observable<T> {
        return this.http
            .get<T>(`${environment.socketApi}${path}`, options)
            .pipe(catchError<T, Observable<T>>(this.handleError()));
    }

    put<T = any>(path: string, body: Object = {}): Observable<T> {
        return this.http
            .put<T>(`${environment.socketApi}${path}`, body)
            .pipe(catchError<T, Observable<T>>(this.handleError()));
    }

    post<T = unknown>(path: string, body: Object = {}): Observable<T> {
        return this.http
            .post<T>(`${environment.socketApi}${path}`, body)
            .pipe(catchError(() => throwError(this.handleError())));
    }

    getFromAPI<T = unknown>(path: string): Observable<T> {
        return this.http
            .get<T>(`${this.api}${path}`)
            .pipe(catchError(() => throwError(this.handleError())));
    }

    postCoreSystem<T = unknown>(
        path: string,
        body: Object = {}
    ): Observable<any> {
        return this.http
            .post<T>(`${this.api}${path}`, body)
            .pipe(catchError(() => throwError(this.handleError())));
    }

    delete<T = any>(path: string): Observable<T> {
        return this.http
            .delete<T>(`${environment.socketApi}${path}`)
            .pipe(catchError(() => throwError(this.handleError())));
    }

    private handleError<T>(result?: T): (error: any) => Observable<T | never> {
        return (error: any): Observable<T> => {
            switch (error && error.status) {
                case 401:
                case 403:
                    this.errorService.handleFatalError(
                        ErrorType.authentication
                    );
                    return of(result as T);
                default:
                    return throwError(error);
            }
        };
    }
}
