<div *ngIf="element?.context" class="accordion-element">
    <mat-accordion>
        <mat-expansion-panel
            *ngFor="let tab of element.context.tabs; let i = index"
            [expanded]="i === value"
            (opened)="onOpened(i)"
            (closed)="onClosed(i)"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ tab.title }}
                </mat-panel-title>
                <mat-panel-description>
                    {{ tab.subtitle }}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div [innerHTML]="tab.content"></div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
