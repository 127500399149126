import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    SyncedElementComponent,
    SyncedElement,
    AccordionElementContext,
    ContentElementComponent,
} from '@app/shared-modules/content-view/models';

@Component({
    selector: 'app-accordion-element',
    templateUrl: './accordion-element.component.html',
    styleUrls: ['./accordion-element.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionElementComponent
    extends SyncedElementComponent<
        SyncedElement<AccordionElementContext>,
        number
    >
    implements ContentElementComponent
{
    @Input() element: SyncedElement<AccordionElementContext>;

    onOpened(value: number): void {
        this.updateValue(value);
        this.broadcastValue(value);
    }

    onClosed(index: number): void {
        if (this.value !== index) {
            return;
        }

        this.onOpened(null);
    }
}
