import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import * as RoomState from '@app/room/store/conference.state';
import { Store } from '@ngrx/store';
import * as fromCore from '@app/core/store';
import { map } from 'rxjs/operators';
import { ActiveToolType } from '@libs/models';
import { combineLatest, Observable } from 'rxjs';
import { RoomActions } from '@app/room/store/actions';

@Component({
    selector: 'app-end-call-button',
    templateUrl: './end-call-button.component.html',
    styleUrls: ['./end-call-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EndCallButtonComponent implements OnInit {
    shouldDisplay$: Observable<boolean>;

    constructor(private readonly store: Store<fromCore.CoreState>) {}

    ngOnInit(): void {
        const correctTool$ = this.store
            .select(RoomState.getActiveTool)
            .pipe(map((tool) => tool !== ActiveToolType.EndSession));
        const correctRole$ = this.store.select(fromCore.selectIsDriver);

        this.shouldDisplay$ = combineLatest([correctRole$, correctTool$]).pipe(
            map(([correctRole, correctTool]) => correctRole && correctTool)
        );
    }

    endCall(): void {
        this.store.dispatch(
            RoomActions.changeTool({ tool: ActiveToolType.EndSession })
        );
    }
}
