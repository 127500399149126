import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import {
    TimepickerComponent,
    TimepickerSlideComponent,
} from '@app/shared-modules/timepicker/components';

const COMPONENTS = [TimepickerComponent, TimepickerSlideComponent];

@NgModule({
    declarations: [COMPONENTS],
    exports: [COMPONENTS],
    imports: [CommonModule, MatButtonModule, MatIconModule],
})
export class TimepickerModule {}
