import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import * as fromCore from '@app/core/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorageService } from '@app/core/services';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
    token: string | null;

    constructor(
        private router: Router,
        private store: Store<fromCore.CoreState>,
        private localStorageService: LocalStorageService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.store
            .select(fromCore.selectGlobalParameters)
            .pipe(untilDestroyed(this))
            .subscribe((settings) => {
                this.token = settings.token;
            });

        let newRequest = request.clone({
            setHeaders: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
        });

        if (this.token) {
            const contentType = {};
            if (!request.headers.get('Custom-Header')) {
                contentType['Content-Type'] = 'application/json';
            }
            newRequest = request.clone({
                setHeaders: {
                    ...{
                        'Cache-Control': 'no-cache',
                        Authorization: `Bearer ${this.token}`,
                    },
                    ...contentType,
                },
            });
        }

        return next.handle(newRequest).pipe(
            catchError((response: any) => {
                if (
                    response instanceof HttpErrorResponse &&
                    response.status === 401
                ) {
                    this.localStorageService.remove('globalParameters');
                    this.router.navigateByUrl('/');
                }
                return throwError(response);
            })
        );
    }
}
