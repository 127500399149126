import { Component, OnInit } from '@angular/core';
import * as fromCore from '@app/core/store';
import { Store } from '@ngrx/store';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouterDataHelperService } from '@app/core/services';

@Component({
    selector: 'app-header',
    templateUrl: './header.container.html',
    styleUrls: ['./header.container.scss'],
})
export class HeaderContainer implements OnInit {
    displayControls$: Observable<boolean>;

    constructor(
        private readonly store: Store<fromCore.CoreState>,
        private readonly routerDataHelperService: RouterDataHelperService
    ) {}

    ngOnInit(): void {
        const routeData$ = this.routerDataHelperService.data$;

        this.displayControls$ = combineLatest([
            routeData$,
            this.store.select(fromCore.selectIsAuthenticated),
        ]).pipe(
            map(([routeData, isAuthenticated]) => {
                const correctRoute = !!routeData.displayNavigationControls;
                return correctRoute && isAuthenticated;
            })
        );
    }
}
