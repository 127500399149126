import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { FileUploadResponse } from '@app/core/models';

export interface SlideUploadResponse {
    slideUrl: string;
    thumbnailUrl: string;
}
@Injectable({
    providedIn: 'root',
})
export class FileUploadService {
    classroomApi: string;

    constructor(private http: HttpClient) {
        this.classroomApi = environment.socketApi;
    }

    uploadFile<T = FileUploadResponse>(url: string, file: File): Observable<T> {
        const fullUrl = `${this.classroomApi}${url}`;

        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        const headers = new HttpHeaders({
            'Custom-Header': 'true',
        });

        return this.http.post<T>(fullUrl, formData, { headers });
    }
}
