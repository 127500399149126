import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Components } from '@app/shared-modules/table-edit/components';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { Pipes } from '@app/shared-modules/table-edit/pipes';

@NgModule({
    declarations: [Components, Pipes],
    exports: [Components],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
    ],
})
export class TableEditModule {}
