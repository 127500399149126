import { Injectable } from '@angular/core';
import { ParentCourse, Section } from '@app/room/models';
import { Course, Lecture, Slide } from '@libs/models';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SlidesService } from './slides.service';
import { ApiService } from './api.service';

export interface CourseResponse {
    data: Course;
}

export interface LectureResponse {
    lecture: Lecture;
    sections: Section[];
}

function sortByOrderField(a: Slide, b: Slide): number {
    if (a.order < b.order) {
        return -1;
    }
    if (a.order > b.order) {
        return 1;
    }
    return 0;
}

@Injectable({
    providedIn: 'root',
})
export class ContentService {
    static sortCourseLectures(course: Course): Course {
        course.lectures.sort(sortByOrderField);
        return course;
    }

    parentCoursesCache: { id: number; courses: ParentCourse[] } = {
        id: null,
        courses: [],
    };

    constructor(
        private apiService: ApiService,
        private slidesService: SlidesService
    ) {}

    getCourseById(courseId: Number): Observable<Course> {
        return this.apiService
            .get<CourseResponse>(`/conference/courses/${courseId}`)
            .pipe(
                map(({ data }) => data),
                map((course) => ContentService.sortCourseLectures(course))
            );
    }

    getLectureById(id: number): Observable<Lecture> {
        return this.apiService
            .get<LectureResponse>(`/conference/lectures/${id}`)
            .pipe(
                map((response) => ({
                    ...response.lecture,
                    slides: this.checkSlides(response.lecture.slides),
                    sections: response.sections.map((section) => {
                        const slides = this.checkSlides(section.slides);

                        return {
                            ...section,
                            slides,
                        };
                    }),
                }))
            );
    }

    getParentCourses(id: number): Observable<ParentCourse[]> {
        if (this.parentCoursesCache.id === id) {
            return of(this.parentCoursesCache.courses);
        }

        return this.apiService
            .get<{ data: ParentCourse[] }>(`/conference/courses/${id}/parents`)
            .pipe(
                map((response) => response.data),
                tap((courses) => {
                    this.parentCoursesCache.id = id;
                    this.parentCoursesCache.courses = courses;
                })
            );
    }

    private checkSlides(slides: Slide[]): Slide[] {
        slides.sort(sortByOrderField);

        return slides.map((slide) => this.slidesService.checkSlide(slide));
    }
}
