import {
    Component,
    forwardRef,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ImageInputComponent),
    multi: true,
};

@Component({
    selector: 'app-image-input',
    templateUrl: './image-input.component.html',
    styleUrls: ['./image-input.component.scss'],
    providers: [VALUE_ACCESSOR],
})
export class ImageInputComponent implements ControlValueAccessor {
    @Input()
    label: string;

    @Output()
    readonly selected = new EventEmitter<File>();

    value: string;

    src: string;

    readFile(file: File): void {
        const fileReader = new FileReader();

        fileReader.onload = (event) => {
            const src = event.target.result;
            if (typeof src !== 'string') {
                return;
            }

            this.src = src;
        };

        fileReader.readAsDataURL(file);

        this.selected.emit(file);
    }

    clearValue(): void {
        this.writeValue(null);
        this.src = null;
    }

    writeValue(value: string): void {
        this.value = value;

        this.src = value;

        this.onChange(this.value);
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected onChange = (_: string) => {};

    protected onTouched = () => {};
}
