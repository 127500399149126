import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { Components } from './components';

@NgModule({
    declarations: [Components],
    imports: [CommonModule, MatIconModule, MatButtonModule],
    exports: [Components],
})
export class InfoCardModule {}
