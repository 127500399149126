import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';

import { environment } from '@env';

import { merge } from 'lodash';

import { forkJoin, Observable } from 'rxjs';
import { map, repeat, take } from 'rxjs/operators';

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
    contentHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    });

    constructor(private readonly http: HttpClient) {}

    getTranslation(lang: string): Observable<any> {
        const apiAddress = `${environment.langApi}/${lang}`;

        const local = this.http.get(`/assets/i18n/${lang}.json`);
        const remote = this.http.get(apiAddress, {
            headers: this.contentHeader,
        });

        return forkJoin([local, remote]).pipe(
            take(1),
            repeat(2),
            map((result: any) => merge(result[0], result[1]))
        );
    }
}
