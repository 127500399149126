import { Component, Input } from '@angular/core';
import { BulletPointStyle } from '../../models';

@Component({
    selector: 'app-icon-bullet-point',
    templateUrl: './icon-bullet-point.component.html',
    styleUrls: ['./icon-bullet-point.component.scss'],
})
export class IconBulletPointComponent {
    @Input()
    set style(style: BulletPointStyle) {
        switch (style) {
            case BulletPointStyle.Checkmark:
                this.icon = 'check';
                break;
            case BulletPointStyle.ExclamationPoint:
                this.icon = 'priority_high';
                break;
            default:
                this.icon = 'arrow_forward';
                break;
        }
    }

    icon: string;
}
