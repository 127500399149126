import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AvatarModule } from '@app/shared-modules/avatar/avatar.module';
import { Components } from '@app/shared-modules/participants/components';
import { Containers } from '@app/shared-modules/participants/containers';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [Containers, Components],
    imports: [CommonModule, AvatarModule, MatIconModule, TranslateModule],
    exports: [Containers, Components],
})
export class ParticipantsModule {}
