import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RatingComponent } from '@app/shared-modules/rating/components';

@NgModule({
    declarations: [RatingComponent],
    imports: [CommonModule, MatButtonModule, MatTooltipModule, MatIconModule],
    exports: [RatingComponent],
})
export class RatingModule {}
