<app-sidebar-button
    class="sidebar__button"
    icon="settings"
    [label]="'label.settings' | translate"
    (click)="openSettings()"
></app-sidebar-button>

<app-sidebar-button
    class="sidebar__button"
    icon="help_outline"
    [label]="'label.help' | translate"
    (click)="openHelp()"
></app-sidebar-button>

<mat-divider class="sidebar__divider"></mat-divider>

<div class="sidebar__participants">
    <app-participants></app-participants>
</div>
