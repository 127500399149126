import { Injectable } from '@angular/core';
import * as fromCore from '@app/core/store';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class TranslationInitializerService {
    private readonly defaultLanguage = 'en';

    private readonly availableLanguages = ['en', 'de', 'es'];

    constructor(
        private readonly translateService: TranslateService,
        private readonly store: Store<fromCore.CoreState>
    ) {}

    init(): void {
        this.translateService.setDefaultLang(this.defaultLanguage);
        this.translateService.addLangs(this.availableLanguages);

        this.store
            .pipe(select(fromCore.selectLanguage), untilDestroyed(this))
            .subscribe((language) => {
                this.translateService.use(language || this.getLanguage());
            });
    }

    private getLanguage(): string {
        const browserLanguage = this.translateService.getBrowserLang();

        const isUnavailableLanguage =
            !this.availableLanguages.includes(browserLanguage);

        return isUnavailableLanguage ? this.defaultLanguage : browserLanguage;
    }
}
