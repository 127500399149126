<div class="timer__wrapper">
    <app-timer-progress [progress]="progress"></app-timer-progress>

    <div class="timer__content">
        <app-timer-countdown [time]="timeLeft"></app-timer-countdown>

        <div class="timer__actions">
            <app-timer-actions
                [status]="status$ | async"
                (started)="start()"
                (stopped)="stop()"
            ></app-timer-actions>
        </div>
    </div>
</div>
