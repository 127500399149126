import { ParentCourse } from '@app/room/models';
import { ContentNavigationParameters } from '@app/room/store/models';
import { Course, Lecture } from '@libs/models';
import { createAction, props, union } from '@ngrx/store';

export enum ContentActionType {
    FetchCourse = '[Content] Fetch Course',
    FetchCourseSuccess = '[Content] Fetch Course - Success',
    FetchCourseFailure = '[Content] Fetch Course - Failure',

    FetchLecture = '[Content] Fetch Lecture',
    FetchLectureSuccess = '[Content] Fetch Lecture - Success',
    FetchLectureFailure = '[Content] Fetch Lecture - Failure',

    CheckContent = '[Content] Check Content',

    FetchParentCourses = '[Content] Fetch Parent Courses',
    FetchParentCoursesSuccess = '[Content] Fetch Parent Courses - Success',
}

export const fetchCourse = createAction(
    ContentActionType.FetchCourse,
    props<{ id: number }>()
);

export const fetchCourseSuccess = createAction(
    ContentActionType.FetchCourseSuccess,
    props<{ course: Course }>()
);

export const fetchCourseFailure = createAction(
    ContentActionType.FetchCourseFailure,
    props<{ payload: any }>()
);

export const fetchLecture = createAction(
    ContentActionType.FetchLecture,
    props<{ id: number }>()
);

export const fetchLectureSuccess = createAction(
    ContentActionType.FetchLectureSuccess,
    props<{ lecture: Lecture }>()
);

export const fetchLectureFailure = createAction(
    ContentActionType.FetchLectureFailure,
    props<{ payload: any }>()
);

export const checkContent = createAction(
    ContentActionType.CheckContent,
    props<{ navigation: ContentNavigationParameters }>()
);

export const fetchParentCourses = createAction(
    ContentActionType.FetchParentCourses,
    props<{ id: number }>()
);

export const fetchParentCoursesSuccess = createAction(
    ContentActionType.FetchParentCoursesSuccess,
    props<{ parentCourses: ParentCourse[] }>()
);

const all = union({
    fetchCourse,
    fetchCourseSuccess,
    fetchCourseFailure,
    fetchLecture,
    fetchLectureSuccess,
    fetchLectureFailure,
    fetchParentCourses,
    fetchParentCoursesSuccess,
});

export type ContentActionsUnion = typeof all;
