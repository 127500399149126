import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-timer-progress',
    templateUrl: './timer-progress.component.html',
    styleUrls: ['./timer-progress.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimerProgressComponent {
    @Input()
    progress: number;
}
