import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import * as fromCore from '@app/core/store';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { UserProfile } from '@app/core/store/models';
import { map } from 'rxjs/operators';
import { RouterDataHelperService } from '@app/core/services';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    /** Child components use sidebar styles */
    // tslint:disable-next-line:use-component-view-encapsulation
    encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
    @Output()
    readonly closeSidebar = new EventEmitter();

    isEditor$: Observable<boolean>;

    userProfile$: Observable<UserProfile>;

    displayControls$: Observable<boolean>;

    constructor(
        private readonly store: Store<fromCore.CoreState>,
        private readonly routerDataHelperService: RouterDataHelperService
    ) {}

    ngOnInit(): void {
        this.userProfile$ = this.store.select(fromCore.selectUserProfile);
        this.isEditor$ = this.store.select(fromCore.selectIsEditor);

        this.displayControls$ = combineLatest([
            this.routerDataHelperService.data$,
            this.userProfile$,
        ]).pipe(
            map(([routeData, userProfile]) => {
                const correctRoute = !!routeData.displayNavigationControls;
                return correctRoute && !!userProfile;
            })
        );
    }

    close(): void {
        this.closeSidebar.emit();
    }
}
