import { Reaction } from './reaction.interface';

export const Reactions: Reaction[] = [
    {
        emoji: '👍',
        label: 'Thumbs up',
    },
    {
        emoji: '👏',
        label: 'Clapping hands',
    },
    {
        emoji: '😀',
        label: 'Grinning face',
    },
    {
        emoji: '😅',
        label: 'Grinning face with sweat drop',
    },
    {
        emoji: '🙈',
        label: 'Do not see you',
    },
    {
        emoji: '🙉',
        label: 'Do not hear you',
    },
];
