import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-participant',
    templateUrl: './participant.component.html',
    styleUrls: ['./participant.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantComponent {
    @Input()
    set participant(participant: any) {
        this._participant = participant;
        this.connectionLevelClass = this.selectConnection(
            participant.connectivity
        );
    }

    _participant: any;

    connectionLevelClass: string;

    selectConnection(type: number): string {
        switch (type) {
            case 1:
                return 'participant__connection-level--low';
            case 2:
                return 'participant__connection-level--mid';
            case 3:
                return 'participant__connection-level--high';
            default:
                return 'participant__connection-level--high';
        }
    }
}
