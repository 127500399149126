import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-sidebar-button',
    templateUrl: './sidebar-button.component.html',
    styleUrls: ['./sidebar-button.component.scss'],
})
export class SidebarButtonComponent {
    @Input()
    set path(path: string[]) {
        if (!path || !Array.isArray(path) || !path.length) {
            this.isLink = false;
            return;
        }

        this.isLink = true;
        this.routerLink = path;
    }

    @Input()
    icon: string;

    @Input()
    label: string;

    isLink = false;

    routerLink: string[] = [];
}
