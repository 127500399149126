import { Component, Input } from '@angular/core';
import { Row } from '@app/shared-modules/content-view/models';
import { LectureVersion, Slide } from '@libs/models';
import { SlidePreset } from '@app/admin/models';

@Component({
    selector: 'app-slide-preview',
    templateUrl: './slide-preview.component.html',
    styleUrls: ['./slide-preview.component.scss'],
})
export class SlidePreviewComponent {
    @Input()
    set slide(slide: Slide) {
        if (!slide) {
            return;
        }

        this.content = slide.content;
        this.isFirstSlide = slide.preset === SlidePreset.FirstSlide;
    }

    @Input()
    lectureVersion: LectureVersion;

    content: Row[];

    isFirstSlide: boolean;

    readonly LectureVersion = LectureVersion;
}
