import { Injectable } from '@angular/core';
import { ErrorType, FatalError } from '@app/error/models';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ErrorService {
    fatalError$: Observable<FatalError>;

    private readonly defaultErrorTitle = 'error.defaultErrorTitle';

    private readonly notFoundError = {
        type: ErrorType.notFound,
    };

    private fatalError$$ = new BehaviorSubject<FatalError>(this.notFoundError);

    constructor(
        private readonly toastrService: ToastrService,
        private readonly translateService: TranslateService,
        private readonly router: Router
    ) {
        this.fatalError$ = this.fatalError$$.asObservable();
    }

    handleFatalError(type: ErrorType, data?: any) {
        this.fatalError$$.next({ type, data });
        this.router.navigate(['/', 'error']);
    }

    resetFatalError() {
        this.fatalError$$.next(this.notFoundError);
    }

    handleError(message: string, title?: string) {
        const toastrTitle =
            title || this.translateService.instant(this.defaultErrorTitle);
        this.toastrService.error(message, toastrTitle);
    }
}
