<div *ngIf="element?.context" class="slider-element">
    <p class="mat-h4">{{ element.context.label }}</p>
    <div class="slider-element__content">
        <mat-slider
            [max]="element.context.max"
            [min]="element.context.min"
            [thumbLabel]="element.context.thumbLabel"
            [step]="element.context.step"
            [ngModel]="value"
            (ngModelChange)="onChange($event)"
        ></mat-slider>
        <p class="slider-element__value mat-headline-1">{{ value }}</p>
    </div>
</div>
