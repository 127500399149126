<div class="avatar__wrapper">
    <img
        class="avatar"
        [src]="_src"
        [attr.alt]="alt"
        [ngStyle]="{ 'width.px': size, 'height.px': size }"
    />
    <div
        class="avatar__border"
        [ngStyle]="{ 'width.px': size, 'height.px': size }"
    ></div>
</div>
