import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActiveToolType, ViewModeType } from '@libs/models';

import { TranslateService } from '@ngx-translate/core';

import { Store } from '@ngrx/store';
import { SettingsActions } from '@app/core/store/actions';
import * as fromCore from '@app/core/store';

import { RoomActions } from '@app/room/store/actions';

@UntilDestroy()
@Component({
    selector: 'app-help',
    templateUrl: './help.container.html',
    styleUrls: ['./help.container.scss'],
})
export class HelpContainer implements OnInit {
    id: string;

    viewType: any = ActiveToolType;

    issues$: Observable<any[]>;

    helpArticles: string[];

    teamviewerUrl: string;

    customIssues = [
        {
            title: 'Custom',
            body: 'Custom Body',
        },
    ];

    constructor(
        private store: Store<fromCore.CoreState>,
        private translateService: TranslateService
    ) {
        this.teamviewerUrl = 'https://get.teamviewer.com/volunteervision';
        this.helpArticles = ['HEAR', 'SEE', 'AUDIOQUALITY', 'MORE'];
    }

    ngOnInit(): void {
        try {
            this.store
                .select(fromCore.selectExternalRoomId)
                .pipe(untilDestroyed(this))
                .subscribe((id) => {
                    this.id = id;
                });

            this.issues$ = this.translateService
                .get('help.issues.list')
                .pipe(map((data) => Object.assign(data, this.customIssues)));
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }

    backToSlides(): void {
        this.onNavigate(ActiveToolType.Content);
    }

    onNavigate(tool: ActiveToolType): void {
        this.store.dispatch(RoomActions.changeTool({ tool }));
    }

    turnOffVideo(): void {
        this.store.dispatch(
            SettingsActions.setMode({ mode: ViewModeType.Othertool })
        );
    }
}
