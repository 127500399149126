import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import * as fromCore from '@app/core/store';
import { SettingsActions } from '@app/core/store/actions';
import { GlobalParameters } from '@app/core/store/models';
import { ViewModeType } from '@libs/models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

/**
 * This guard makes sure that global parameters were fetched from url
 *
 * should be used for /room/:roomId route only
 */
@Injectable({ providedIn: 'root' })
export class GlobalParametersGuard implements CanActivate {
    wasFetched = false;

    constructor(private readonly store: Store<fromCore.CoreState>) {}

    canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
        if (this.wasFetched) {
            return true;
        }

        this.saveGlobalParameters(this.fetchGlobalParameters(route));
        this.wasFetched = true;

        return true;
    }

    fetchGlobalParameters(
        route: ActivatedRouteSnapshot
    ): GlobalParameters | null {
        const externalRoomId = route.queryParams.room;
        const { token } = route.queryParams;
        const lang = route.queryParams.lang || route.queryParams.language;
        const mode = this.getMode(route);

        /**
         * early exit for 'reload page'/'wrong link' case
         * last is handled in AuthState's effect authenticateByToken;
         */
        return !externalRoomId && !token
            ? null
            : {
                  externalRoomId,
                  token,
                  lang,
                  mode,
              };
    }

    saveGlobalParameters(globalParameters: GlobalParameters | null): void {
        if (!globalParameters) {
            return;
        }

        this.store.dispatch(
            SettingsActions.setGlobalParameters(globalParameters)
        );
    }

    getMode(route: ActivatedRouteSnapshot): ViewModeType {
        /**
         * We found an issue, sometimes it takes 'default;' instead of
         * correct 'default' mode. While we are looking for reasons, that
         * should fix the problem on frontend.
         */
        const mode = route.queryParams.mode?.replace(/[^a-zA-Z]+/g, '');

        if (!mode) {
            return ViewModeType.Default;
        }

        if (mode === 'noSync') {
            return ViewModeType.Preview;
        }

        return mode as ViewModeType;
    }
}
