import { Component, Input } from '@angular/core';
import {
    ContentElementComponent,
    TitleElementContext,
} from '@app/shared-modules/content-view/models';
import { ContentElement } from '@libs/models';

@Component({
    selector: 'app-title-element',
    templateUrl: './title-element.component.html',
    styleUrls: ['./title-element.component.scss'],
})
export class TitleElementComponent implements ContentElementComponent {
    @Input()
    set element(element: ContentElement<TitleElementContext>) {
        this.words = element.context.text.trim().split(' ');
        this.startColorFromIdx = Math.ceil(this.words.length / 2);
    }

    words: string[];

    startColorFromIdx: number;
}
