import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
    @Input()
    set src(src: any) {
        if (src && src.small && src.medium) {
            this._src = this.size > 100 ? src.medium : src.small;
        } else if (src) {
            this._src = src;
        } else {
            this._src = this.defaultSrc;
        }
    }

    @Input()
    size: string | number = 16;

    @Input()
    alt = 'Avatar';

    readonly defaultSrc = 'assets/img/empty_avatar.png';

    _src: object | string = this.defaultSrc;
}
