<mat-icon
    *ngIf="text"
    color="primary"
    class="hint__icon"
    [matTooltip]="text"
    matTooltipPosition="before"
    matTooltipClass="text-preformatted"
>
    info
</mat-icon>
