import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    ContentElementComponent,
    TextElementContext,
} from '@app/shared-modules/content-view/models';
import { ContentElement } from '@libs/models';

@Component({
    selector: 'app-text-element',
    templateUrl: './text-element.component.html',
    styleUrls: ['./text-element.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextElementComponent implements ContentElementComponent {
    @Input() element: ContentElement<TextElementContext>;
}
