export enum ErrorType {
    authentication = 'AUTHENTICATION',
    shutdown = 'SHUTDOWN',
    timeLimitReached = 'TIME_LIMIT_REACHED',
    unknown = 'UNKNOWN',
    noDeviceFound = 'NO_DEVICE_FOUND',
    mediaAccess = 'MEDIA_ACCESS',
    hardware = 'HARDWARE',
    notFound = 'NOT_FOUND',

    // todo:
    serverDoesNotRespond = 'SERVER_DOES_NOT_RESPOND',
}
