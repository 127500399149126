import { HttpClient } from '@angular/common/http';
import { MatSidenavModule } from '@angular/material/sidenav';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    CustomMissingTranslationHandler,
    CustomTranslateLoader,
} from '@app/core/helpers';
import { HeaderModule } from '@app/header/header.module';
import { SentryModule } from '@app/sentry';
import { SidebarModule } from '@app/sidebar/sidebar.module';
import { environment } from '@env';
import {
    MissingTranslationHandler,
    TranslateLoader,
    TranslateModule,
} from '@ngx-translate/core';

import { DebugModule } from '@app/shared-modules';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomTranslateLoader,
                deps: [HttpClient],
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: CustomMissingTranslationHandler,
                deps: [HttpClient],
            },
        }),
        HeaderModule,
        SidebarModule,
        DebugModule,
        environment.sentryDSN ? SentryModule : [],
        MatSidenavModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.serviceWorkerEnabled,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
})
export class AppModule {}
