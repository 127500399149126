export enum SocketEvent {
    conferenceRoomBroadcast = 'CONFERENCE_ROOM_BROADCAST',

    // new:
    conferenceRoomGetOrUpdateNote = 'CONFERENCE_ROOM_GET_OR_UPDATE_NOTE',
    conferenceRoomSendNote = 'CONFERENCE_ROOM_SEND_NOTE',

    chatUpdate = 'CHAT_UPDATE',
    // message in chat channel for every participant
    chatChannelMessage = 'CHAT_CHANNEL_MESSAGE',

    conferenceAuth = 'CONFERENCE_AUTH',
    conferenceRoomUpdateState = 'CONFERENCE_ROOM_UPDATE_STATE',

    pingEvent = 'PING_EVENT',
    pongEvent = 'PONG_EVENT',
    slowInternetConnectivity = 'SLOW_INTERNET_CONNECTIVITY',
    badConnectionStatusChange = 'BAD_CONNECTION_STATUS_CHANGE',
    userHelloFailEvent = 'USER_HELLOFAIL_EVENT',

    pleaseAuth = 'PLEASE_AUTH', // new
    notAuthenticated = 'NOT_AUTHENTICATED',

    conferenceRoomJoinedSuccess = 'CONFERENCE_ROOM_JOINED_SUCCESS',
    conferenceRoomUsersOnline = 'CONFERENCE_ROOM_USERS_ONLINE',
    conferenceError = 'CONFERENCE_ERROR',
    conferenceRoomStateUpdated = 'CONFERENCE_ROOM_STATE_UPDATED',

    roomSendChatMessage = 'ROOM_SEND_CHAT_MESSAGE',

    userJoinEvent = 'USER_JOIN_EVENT',

    shutdown = 'SHUTDOWN',
}
