<mat-sidenav-container
    class="app"
    [ngClass]="{ 'app--limited-height': limitedHeight$ | async }"
>
    <mat-sidenav mode="over">
        <div>
            <app-sidebar (closeSidebar)="closeSidebar()"></app-sidebar>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <app-header class="app__header"></app-header>

        <div class="app__content">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
