import { Component, Input } from '@angular/core';
import {
    ContentElementComponent,
    MultilineTitleElementContext,
} from '@app/shared-modules/content-view/models';
import { ContentElement } from '@libs/models';

@Component({
    selector: 'app-multiline-title-element',
    templateUrl: './multiline-title-element.component.html',
    styleUrls: ['./multiline-title-element.component.scss'],
})
export class MultilineTitleElementComponent implements ContentElementComponent {
    @Input()
    element: ContentElement<MultilineTitleElementContext>;
}
