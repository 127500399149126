<div class="image-input">
    <p class="image-input__label" *ngIf="label">{{ label }}</p>

    <ng-container *ngIf="!src; else preview">
        <section class="image-input__input-row">
            <app-file-input
                accept=".jpg,.png"
                (selected)="readFile($event)"
                color="accent"
            ></app-file-input>
        </section>
    </ng-container>

    <ng-template #preview>
        <section class="image-input__preview">
            <img [src]="src" width="150px" alt="Image preview" />

            <button
                mat-icon-button
                class="image-input__clear-button"
                type="button"
                (click)="clearValue()"
            >
                <mat-icon>clear</mat-icon>
            </button>
        </section>
    </ng-template>
</div>
