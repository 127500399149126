import { Component, Input } from '@angular/core';
import {
    ContentElementComponent,
    QuoteElementContext,
} from '@app/shared-modules/content-view/models';
import { ContentElement } from '@libs/models';

@Component({
    selector: 'app-quote-element',
    templateUrl: './quote-element.component.html',
    styleUrls: ['./quote-element.component.scss'],
})
export class QuoteElementComponent implements ContentElementComponent {
    @Input()
    element: ContentElement<QuoteElementContext>;
}
