<div class="list-element">
    <ng-container *ngFor="let point of element.context.elements; let i = index">
        <div class="list-element__list">
            <app-bullet-point
                [index]="i + 1"
                [style]="element.context.style"
                class="list-element__list-icon"
            ></app-bullet-point>

            <section>
                <p *ngIf="point.title" class="list-element__list-title">
                    {{ point.title }}
                </p>

                <p>{{ point.text }}</p>
            </section>
        </div>

        <section class="list-element__children">
            <ng-container
                *ngFor="let subElement of point.children"
                [ngTemplateOutlet]="subList"
                [ngTemplateOutletContext]="{ element: subElement }"
            >
            </ng-container>
        </section>
    </ng-container>
</div>

<!-- Sub list template -->
<ng-template #subList let-element="element">
    <div class="list-element__sub-list">
        <section class="list-element__sub-list-header">
            <div class="list-element__sub-list-icon"></div>

            <ng-container
                [ngTemplateOutlet]="element.title ? subListTitle : subListText"
            ></ng-container>
        </section>

        <ng-container
            *ngIf="element.title"
            [ngTemplateOutlet]="subListText"
        ></ng-container>

        <ng-template #subListTitle>
            <p class="list-element__sub-list-title">
                {{ element.title }}
            </p>
        </ng-template>

        <ng-template #subListText>
            <p>{{ element.text }}</p>
        </ng-template>

        <section class="list-element__children">
            <ng-container
                *ngFor="let subElement of element.children"
                [ngTemplateOutlet]="subSubListEl"
                [ngTemplateOutletContext]="{ element: subElement }"
            >
            </ng-container>
        </section>
    </div>
</ng-template>

<!-- Sub Sub List template -->
<ng-template #subSubListEl let-element="element">
    <div class="list-element__sub-sub-list">
        <div
            class="list-element__sub-list-icon list-element__sub-list-icon--mini"
        ></div>

        <p>{{ element }}</p>
    </div>
</ng-template>
