import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthInitiatorGuard, EditorGuard } from '@app/core/guards';
import { SelectedPreloadingStrategy } from '@app/core/services';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () =>
            import('./auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'room',
        loadChildren: () =>
            import('./room/room.module').then((m) => m.RoomModule),
        data: { preload: true },
    },
    {
        path: 'admin',
        data: {
            displayNavigationControls: true,
            seo: { title: 'Admin panel' },
        },
        canActivate: [AuthInitiatorGuard, EditorGuard],
        loadChildren: () =>
            import('./admin/admin.module').then((m) => m.AdminModule),
    },
    {
        path: 'preview',
        loadChildren: () =>
            import('./preview/preview.module').then((m) => m.PreviewModule),
        data: {
            seo: { title: 'Preview material' },
        },
    },
    {
        path: 'onboarding',
        loadChildren: () =>
            import('./onboarding/onboarding.module').then(
                (m) => m.OnboardingModule
            ),
        data: {
            seo: { title: 'Onboarding' },
        },
    },
    {
        path: 'error',
        loadChildren: () =>
            import('./error/error.module').then((m) => m.ErrorModule),
        data: {
            seo: { title: 'Error' },
        },
    },
    {
        path: '**',
        redirectTo: 'error',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: SelectedPreloadingStrategy,
            anchorScrolling: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
