import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
import { Row } from '@app/shared-modules/content-view/models';
import { LectureVersion } from '@libs/models';

@Component({
    selector: 'app-content-view',
    templateUrl: './content-view.component.html',
    styleUrls: ['./content-view.component.scss'],
})
export class ContentViewComponent implements AfterViewInit {
    @Input() content: Row[];

    @Input() renderMedia = true;

    @Input() version = LectureVersion.v2;

    @ViewChild('container', { static: true })
    private containerRef: ElementRef<HTMLDivElement>;

    ngAfterViewInit() {
        if (this.version === LectureVersion.v2) {
            return;
        }

        const observer = new ResizeObserver(() =>
            this.updateSizeCSSAttribute()
        );

        observer.observe(this.containerRef.nativeElement);
    }

    private updateSizeCSSAttribute() {
        this.containerRef.nativeElement.style.setProperty(
            '--slideContentHeight',
            `${this.containerRef.nativeElement.clientHeight.toString(10)}px`
        );
    }
}
