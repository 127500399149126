export function objectsEqual(prev: object, curr: object): boolean {
    let isEqual = true;

    Object.keys(curr).forEach((key) => {
        if (curr[key] !== prev[key]) {
            isEqual = false;
        }
    });

    return isEqual;
}
