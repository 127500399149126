import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    Input,
    Type,
    ViewChild,
} from '@angular/core';
import { AccordionElementComponent } from '@app/shared-modules/content-view/components/accordion-element/accordion-element.component';
import { AspectCardElementComponent } from '@app/shared-modules/content-view/components/aspect-card-element/aspect-card-element.component';
import { AudioElementComponent } from '@app/shared-modules/content-view/components/audio-element/audio-element.component';
import { ContentCardElementComponent } from '@app/shared-modules/content-view/components/content-card-element/content-card-element.component';
import { ImageElementComponent } from '@app/shared-modules/content-view/components/image-element/image-element.component';
import { InputElementComponent } from '@app/shared-modules/content-view/components/input-element/input-element.component';
import { ListCardElementComponent } from '@app/shared-modules/content-view/components/list-card-element/list-card-element.component';
import { ListElementComponent } from '@app/shared-modules/content-view/components/list-element/list-element.component';
import { MentorscriptElementComponent } from '@app/shared-modules/content-view/components/mentorscript-element/mentorscript-element.component';
import { MultilineTitleElementComponent } from '@app/shared-modules/content-view/components/multiline-title-element/multiline-title-element.component';
import { OembedElementComponent } from '@app/shared-modules/content-view/components/oembed-element/oembed-element.component';
import { PreviewElementComponent } from '@app/shared-modules/content-view/components/preview-element/preview-element.component';
import { QuoteElementComponent } from '@app/shared-modules/content-view/components/quote-element/quote-element.component';
import { SliderElementComponent } from '@app/shared-modules/content-view/components/slider-element/slider-element.component';
import { TextElementComponent } from '@app/shared-modules/content-view/components/text-element/text-element.component';
import { TimelineElementComponent } from '@app/shared-modules/content-view/components/timeline-element/timeline-element.component';
import { TimerElementComponent } from '@app/shared-modules/content-view/components/timer-element/timer-element.component';
import { TitleElementComponent } from '@app/shared-modules/content-view/components/title-element/title-element.component';
import { UnknownElementComponent } from '@app/shared-modules/content-view/components/unknown-element/unknown-element.component';
import { DynamicComponentHostDirective } from '@app/shared-modules/content-view/directives/dynamic-component-host.directive';
import {
    ContentElementComponent,
    ContentElementType,
} from '@app/shared-modules/content-view/models';
import { ContentElement } from '@libs/models';
import { TableElementComponent } from '@app/shared-modules/content-view/components/table-element/table-element.component';

@Component({
    selector: 'app-dynamic-element',
    templateUrl: './dynamic-element.component.html',
    styleUrls: ['./dynamic-element.component.scss'],
})
export class DynamicElementComponent implements AfterViewInit {
    @Input()
    element: ContentElement;

    /**
     * Responds for disabling video/audio elements render.
     *
     * We hide them for preview, mostly because some of elements has
     * autoplay and it doesn't makes sense.
     */
    @Input()
    renderMedia = true;

    @ViewChild(DynamicComponentHostDirective)
    host: DynamicComponentHostDirective;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

    ngAfterViewInit(): void {
        this.createComponent();
    }

    private chooseComponent(
        type: ContentElementType
    ): Type<ContentElementComponent> {
        let component;

        switch (type) {
            case ContentElementType.Accordion:
                component = AccordionElementComponent;
                break;
            case ContentElementType.SlideAudioComponent:
                component = this.filterMediaComponent(AudioElementComponent);
                break;
            case ContentElementType.SlideImgComponent:
                component = ImageElementComponent;
                break;
            case ContentElementType.Input:
                component = InputElementComponent;
                break;
            case ContentElementType.AlertNoteComponent:
                component = MentorscriptElementComponent;
                break;
            case ContentElementType.SlideVideoComponent:
            case ContentElementType.SlideOembedComponent:
                component = this.filterMediaComponent(OembedElementComponent);
                break;
            case ContentElementType.Slider:
                component = SliderElementComponent;
                break;
            case ContentElementType.SlideTextComponent:
                component = TextElementComponent;
                break;
            case ContentElementType.Timer:
                component = TimerElementComponent;
                break;
            case ContentElementType.Title:
                component = TitleElementComponent;
                break;
            case ContentElementType.MultilineTitle:
                component = MultilineTitleElementComponent;
                break;
            case ContentElementType.ContentCard:
                component = ContentCardElementComponent;
                break;
            case ContentElementType.Quote:
                component = QuoteElementComponent;
                break;
            case ContentElementType.List:
                component = ListElementComponent;
                break;
            case ContentElementType.ListCard:
                component = ListCardElementComponent;
                break;
            case ContentElementType.AspectCard:
                component = AspectCardElementComponent;
                break;
            case ContentElementType.Timeline:
                component = TimelineElementComponent;
                break;
            case ContentElementType.Table:
                component = TableElementComponent;
                break;
            default:
                component = UnknownElementComponent;
        }

        return component;
    }

    private createComponent(): void {
        const component = this.chooseComponent(this.element.type);
        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(component);

        const { viewContainerRef } = this.host;
        viewContainerRef.clear();

        const componentRef =
            viewContainerRef.createComponent<ContentElementComponent>(
                componentFactory
            );

        componentRef.instance.element = this.element;
        componentRef.changeDetectorRef.detectChanges();
    }

    private filterMediaComponent(
        component: Type<ContentElementComponent>
    ): Type<ContentElementComponent> {
        return this.renderMedia ? component : PreviewElementComponent;
    }
}
