export class GetMillisecondsFrom {
    static seconds(n: number) {
        return n * 1000;
    }

    static minutes(n: number) {
        return n * GetMillisecondsFrom.seconds(60);
    }

    static hours(n: number) {
        return n * GetMillisecondsFrom.minutes(60);
    }
}
