import { Injectable } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    NavigationEnd,
    Router,
} from '@angular/router';
import { filter, map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RouterDataHelperService {
    data$: Observable<any>;

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute
    ) {
        this.data$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.combineRouteData(this.activatedRoute.snapshot)),
            shareReplay(1)
        );
    }

    private combineRouteData(route: ActivatedRouteSnapshot): any {
        return route.firstChild
            ? { ...route.data, ...this.combineRouteData(route.firstChild) }
            : { ...route.data };
    }
}
