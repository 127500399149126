import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule } from '@angular/forms';
import { Components } from './components';

@NgModule({
    declarations: [Components],
    imports: [CommonModule, CKEditorModule, FormsModule],
    exports: [Components],
})
export class EditorModule {}
