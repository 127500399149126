import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebugProvidersModule } from '@app/shared-modules/debug/debug-providers.module';
import { Dialogs } from '@app/shared-modules/debug/dialogs';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';

@NgModule({
    declarations: [Dialogs],
    imports: [
        CommonModule,
        DebugProvidersModule,
        MatDialogModule,
        MatSnackBarModule,
        MatButtonModule,
    ],
})
export class DebugModule {}
