<a [routerLink]="['room']" mat-button>
    {{ 'label.room' | translate }}
</a>

<a [routerLink]="['admin', 'lectures']" mat-button>
    {{ 'label.lectures' | translate }}
</a>

<a [routerLink]="['admin', 'courses']" mat-button>
    {{ 'label.courses' | translate }}
</a>

<a [routerLink]="['admin', 'styleguide']" mat-button>Styleguide</a>

<button *ngIf="isAdmin$ | async" mat-button [matMenuTriggerFor]="modeMenu">
    {{ currentMode$ | async | titlecase }}
</button>

<mat-menu #modeMenu="matMenu">
    <button
        *ngFor="let mode of viewModes"
        mat-menu-item
        (click)="changeViewMode(mode.name)"
    >
        {{ mode.index }}
    </button>
</mat-menu>
