import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

export const PreviewToggle = [
    trigger('previewToggle', [
        state(
            'true',
            style({
                opacity: 1,
                'max-height': '500px',
                visibility: 'visible',
            })
        ),
        state(
            'false',
            style({
                opacity: 0,
                'max-height': '0px',
                visibility: 'hidden',
            })
        ),
        transition('0 => 1', animate('.4s')),
        transition('1 => 0', animate('.2s')),
    ]),
];
