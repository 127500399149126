import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Components } from './components';

@NgModule({
    declarations: [Components],
    exports: [Components],
    imports: [CommonModule],
})
export class ImageModule {}
