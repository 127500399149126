import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromCore from '@app/core/store';
import * as RoomState from '@app/room/store/conference.state';

@Component({
    selector: 'app-participants',
    templateUrl: './participants.container.html',
    styleUrls: ['./participants.container.scss'],
})
export class ParticipantsContainer implements OnInit {
    participants$: Observable<any>;

    constructor(private store: Store<fromCore.CoreState>) {}

    ngOnInit(): void {
        this.participants$ = this.store.select(RoomState.getParticipants);
    }
}
