import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    ContentElementComponent,
    TableElementContext,
} from '@app/shared-modules/content-view/models';
import { ContentElement } from '@libs/models';

@Component({
    selector: 'app-table-element',
    templateUrl: './table-element.component.html',
    styleUrls: ['./table-element.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableElementComponent implements ContentElementComponent {
    @Input() element: ContentElement<TableElementContext>;
}
