import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@app/core/services';
import {
    loadGlobalParameters,
    setGlobalParameters,
    setMode,
    SettingsActionUnion,
} from '@app/core/store/actions/settings.actions';
import { GlobalParameters } from '@app/core/store/models';
import { ViewModeType } from '@libs/models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SettingsEffects {
    setMode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setMode),
            map((action) => action.mode),
            tap((mode) => {
                const url = mode === ViewModeType.Edit ? 'admin' : 'room';
                this.router.navigate([url]);
            }),
            map((mode) => setGlobalParameters({ mode }))
        )
    );

    loadGlobalParameters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadGlobalParameters),
            map(() => {
                const globalParameters =
                    this.localStorageService.get<GlobalParameters>(
                        'globalParameters',
                        true
                    );

                return setGlobalParameters({ ...globalParameters });
            })
        )
    );

    constructor(
        private readonly actions$: Actions<SettingsActionUnion>,
        private readonly router: Router,
        private readonly localStorageService: LocalStorageService
    ) {}
}
