import { Component, Input } from '@angular/core';
import {
    ContentElementComponent,
    OembedElement,
} from '@app/shared-modules/content-view/models';

@Component({
    selector: 'app-oembed-element',
    templateUrl: './oembed-element.component.html',
    styleUrls: ['./oembed-element.component.scss'],
})
export class OembedElementComponent implements ContentElementComponent {
    @Input() set element(element: OembedElement) {
        this._element = element;
        this.hasVideo = element && element.context && !!element.context.url;
    }

    _element: OembedElement;

    hasVideo = false;
}
