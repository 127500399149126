<ng-container *ngIf="isLink; else button">
    <a mat-button class="sidebar-button" [routerLink]="routerLink">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </a>
</ng-container>

<ng-template #button>
    <button mat-button class="sidebar-button">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </button>
</ng-template>

<ng-template #content>
    <mat-icon *ngIf="icon" class="sidebar-button__icon">{{ icon }}</mat-icon>
    {{ label }}
</ng-template>
