import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env';
import { fixWebKitHeight } from '@libs/helpers';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));

/*
    Our app based on device screen height. For max-height we use 100vh.
    Mobile WebKit browsers incorrectly works with 100vh, and part of app
    covers by browser footer. It fixes current problem.
 */
fixWebKitHeight();
