import { RouterStateUrl } from '@app/core/models';
import { authReducer, AuthState } from '@app/core/store/reducers/auth.reducers';
import {
    settingsReducer,
    SettingsState,
} from '@app/core/store/reducers/settings.reducers';
import { AuthSelectors, SettingsSelectors } from '@app/core/store/selectors';
import * as fromRouter from '@ngrx/router-store';
import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MetaReducer,
} from '@ngrx/store';
import { environment } from '@env';
import { ConferenceState } from '@app/room/store/conference.state';

// todo: rename to CoreState
export interface CoreState {
    auth: AuthState;
    settings: SettingsState;
    router: fromRouter.RouterReducerState<RouterStateUrl>;
    conference?: ConferenceState;
}

export const reducers: ActionReducerMap<CoreState> = {
    auth: authReducer,
    settings: settingsReducer,
    router: fromRouter.routerReducer,
};

export function logger(
    reducer: ActionReducer<CoreState>
): ActionReducer<CoreState> {
    return (state: CoreState, action: any): CoreState => {
        /* eslint-disable no-console */
        console.groupCollapsed(action.type);
        console.log('state', state);
        console.log('action', action);
        console.groupEnd();
        /* eslint-enable no-console */

        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<CoreState>[] = !environment.production
    ? [logger]
    : [];

/**
 * Auth State Selectors
 */
export const selectAuth = createFeatureSelector<AuthState>('auth');

export const selectIsAuthenticated = createSelector(
    selectAuth,
    AuthSelectors.getIsAuthenticated
);

export const selectUserProfile = createSelector(
    selectAuth,
    AuthSelectors.getUserProfile
);

export const selectIsAdmin = createSelector(
    selectAuth,
    AuthSelectors.getIsAdmin
);

export const selectIsEditor = createSelector(
    selectAuth,
    AuthSelectors.getIsEditor
);

export const selectIsDriver = createSelector(
    selectAuth,
    AuthSelectors.getIsDriver
);

/**
 * Room State Selectors
 */
export const selectSettings = createFeatureSelector<SettingsState>('settings');

export const selectGlobalParameters = createSelector(
    selectSettings,
    SettingsSelectors.getGlobalParameters
);

export const selectMode = createSelector(
    selectSettings,
    SettingsSelectors.getMode
);

export const selectExternalRoomId = createSelector(
    selectSettings,
    SettingsSelectors.getExternalRoomId
);

export const selectLanguage = createSelector(
    selectSettings,
    SettingsSelectors.getLanguage
);

export const selectTestedState = createSelector(
    selectSettings,
    SettingsSelectors.getTestedState
);

export const selectError = createSelector(
    selectSettings,
    SettingsSelectors.getError
);

/**
 * Router State selectors
 */

export const selectRouter =
    createFeatureSelector<fromRouter.RouterReducerState>('router');

export const { selectUrl } = fromRouter.getSelectors(selectRouter);
