import { Component, forwardRef, Input } from '@angular/core';
import * as ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditorComponent),
    multi: true,
};

@Component({
    selector: 'app-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss'],
    providers: [VALUE_ACCESSOR],
})
export class EditorComponent implements ControlValueAccessor {
    @Input()
    required = false;

    readonly Editor = ClassicEditorBuild;

    readonly editorConfig = {
        link: {
            addTargetToExternalLinks: true,
        },
    };

    value = '';

    writeValue(value: string): void {
        this.value = value;
        this.onChange(this.value);
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    protected onChange = (_: string) => {};

    protected onTouched = () => {};
}
