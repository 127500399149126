import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { Components } from './components';
import { Containers } from './containers';

@NgModule({
    declarations: [Components, Containers],
    imports: [CommonModule, MatProgressSpinnerModule, MatButtonModule],
    exports: [Containers],
})
export class TimerModule {}
