<ng-container *ngIf="shouldDisplay$ | async">
    <button
        mat-icon-button
        color="warn"
        class="end-call-button--icon"
        (click)="endCall()"
        [matTooltip]="'navbar.endSessionTooltip' | translate"
        [attr.aria-label]="'navbar.endSession' | translate"
    >
        <mat-icon>call_end</mat-icon>
    </button>

    <button
        mat-stroked-button
        color="warn"
        [matTooltip]="'navbar.endSessionTooltip' | translate"
        class="end-call-button--stroke"
        (click)="endCall()"
    >
        {{ 'navbar.endSession' | translate }}
    </button>
</ng-container>
