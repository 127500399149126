import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    SyncedElementComponent,
    SliderElementContext,
    SyncedElement,
    ContentElementComponent,
} from '@app/shared-modules/content-view/models';

@Component({
    selector: 'app-slider-element',
    templateUrl: './slider-element.component.html',
    styleUrls: ['./slider-element.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderElementComponent
    extends SyncedElementComponent<SyncedElement<SliderElementContext>, number>
    implements ContentElementComponent
{
    @Input() element: SyncedElement<SliderElementContext>;

    onChange(value: number): void {
        this.updateValue(value);
        this.broadcastValue(value);
    }
}
