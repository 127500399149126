import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromCore from '@app/core/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Avatar } from '@app/core/store/models';
import { SidebarService } from '@app/sidebar/services';
import { UIService } from '@app/core/services';

@Component({
    selector: 'app-controls',
    templateUrl: './controls.container.html',
    styleUrls: ['./controls.container.scss'],
})
export class ControlsContainer implements OnInit {
    displayEndCallButton$: Observable<boolean>;

    isEditor$: Observable<boolean>;

    avatar$: Observable<Avatar>;

    constructor(
        private readonly store: Store<fromCore.CoreState>,
        private readonly uiService: UIService,
        private readonly sidebarService: SidebarService
    ) {}

    ngOnInit(): void {
        this.displayEndCallButton$ = this.uiService.displayEndCallButton$;
        this.isEditor$ = this.store.select(fromCore.selectIsEditor);
        this.avatar$ = this.store
            .select(fromCore.selectUserProfile)
            .pipe(map((profile) => profile && profile.avatar));
    }

    toggleSidebar(): void {
        this.sidebarService.open();
    }
}
