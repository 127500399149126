import { Component, EventEmitter, Output } from '@angular/core';
import { SettingsDialog } from '@app/shared-modules/settings/dialogs/settings/settings.dialog';
import { ActiveToolType } from '@libs/models';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { HelpContainer } from '@app/room/dialogs/help/help.container';

@Component({
    selector: 'app-sidebar-user-controls',
    templateUrl: './sidebar-user-controls.component.html',
    styleUrls: ['./sidebar-user-controls.component.scss'],
})
export class SidebarUserControlsComponent {
    ActiveToolType = ActiveToolType;

    @Output()
    readonly closeSidebar = new EventEmitter();

    constructor(private readonly dialog: MatDialog) {}

    openSettings(): void {
        this.closeSidebar.emit();
        this.dialog.open(SettingsDialog);
    }

    openHelp(): void {
        this.dialog.open(HelpContainer);
    }
}
