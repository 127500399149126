import {
    AfterViewInit,
    ChangeDetectorRef,
    Directive,
    Input,
} from '@angular/core';
import { BroadcastPacketType } from '@app/room/models';
import { SyncedElementUpdatePacket } from '@app/shared-modules/content-view/models/synced-element-update-packet.interface';
import { SyncedElement } from '@app/shared-modules/content-view/models/synced-element.interface';
import { SyncedElementsService } from '@app/shared-modules/content-view/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/**
 * Basic Idea:
 *
 *  1. Component on Client A emits update
 *  2. Server receives update and forwards it to all participants in room
 *  3. Component on Client A,B receives update and sees visual state
 *
 *  We do not use the local NGRX Store (because I think it's not needed. The state update will only effect this component)
 *
 *  Open tbd: how to handle it if every client has his own state of objects an not one has it all?
 *
 * A: SyncedStateStructure
 *
 *
 * The goal of this component is to create a REUSABLE state synchronisation of two elements, with every abstract interface.
 */

/*
 Angular compiler requires Directive/Component decorator for classes
 that uses angular hooks or DI. Directive is the simplest option. So as we
 will extend that class I decided to use that one.
 */
@UntilDestroy()
@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class SyncedElementComponent<Element, Value>
    implements AfterViewInit
{
    @Input()
    element: SyncedElement & Element;

    /**
     * Do not change value directly, use updateValue instead, as this change
     * should trigger change detector.
     */
    value: Value;

    protected constructor(
        private syncedElementsService: SyncedElementsService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngAfterViewInit(): void {
        this.updateValue(
            this.syncedElementsService.loadFromLocalStorage(this.id)
        );

        this.syncedElementsService
            .getUpdatesByID<Value>(this.id)
            .pipe(untilDestroyed(this))
            .subscribe((value) => {
                if (!this.hasChanges(value)) {
                    return;
                }

                this.updateValue(value);
            });
    }

    get id(): string {
        return this.element.id;
    }

    /**
     * Should be called to push new changes
     * @param value
     */
    broadcastValue(value: Value): void {
        const packet: SyncedElementUpdatePacket<Value> = {
            value,
            type: BroadcastPacketType.syncedElementUpdate,
            id: this.id,
        };

        this.syncedElementsService.update(packet).toPromise();
    }

    /**
     * This method should check if there is a difference between new and old value.
     * If value is not primitive, child of that component should has his own
     * implementation.
     *
     * @param value - actually, changes
     * @protected
     */
    protected hasChanges(value: Value): boolean {
        if (typeof value === 'object' && value !== null) {
            // eslint-disable-next-line no-console
            console.error(
                'newValue is not primitive. Component should implement its own hasChanges method'
            );
        }

        return this.value !== value;
    }

    protected updateValue(value: Value): void {
        this.value = value;
        this.syncedElementsService.saveInLocalStorage({ value, id: this.id });
        this.changeDetectorRef.detectChanges();
    }
}
