import { Pipe, PipeTransform } from '@angular/core';
import { ContentElementType } from '@app/shared-modules/content-view/models';
import { ImageElementContext } from '@app/shared-modules/content-view/models/image-element-context.interface';
import {
    Course,
    ContentEntityType,
    Lecture,
    Slide,
    ContentElement,
} from '@libs/models';

@Pipe({
    name: 'preview',
})
export class PreviewPipe implements PipeTransform {
    readonly placeholder = 'assets/img/movie-film-roll.svg';

    transform(entity: Course | Lecture | Slide): string {
        if (entity.preview) {
            return entity.preview;
        }

        if (entity.type !== ContentEntityType.slide) {
            return this.placeholder;
        }

        const slide = entity as Slide;

        if (
            !slide.content ||
            !slide.content.length ||
            !Array.isArray(slide.content[0].columns) ||
            !slide.content[0].columns.length
        ) {
            return this.placeholder;
        }

        const imgElement: ContentElement<ImageElementContext> =
            slide.content[0].columns[0].find(
                ({ type }) => type === ContentElementType.SlideImgComponent
            );

        if (!imgElement) {
            return this.placeholder;
        }

        const { src } = imgElement.context;

        return src || this.placeholder;
    }
}
