import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { Components } from '@app/header/components';
import { Containers, ExportContainers } from '@app/header/containers';
import { ParticipantsModule, SettingsModule } from '@app/shared-modules';
import { AvatarModule } from '@app/shared-modules/avatar/avatar.module';
import { TranslateModule } from '@ngx-translate/core';

const MATERIAL_MODULES = [
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    MatTooltipModule,
    MatInputModule,
    MatSelectModule,
    MatProgressBarModule,
    MatTabsModule,
    MatRadioModule,
    MatDialogModule,
];

@NgModule({
    declarations: [Containers, Components],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        AvatarModule,
        TranslateModule,
        SettingsModule,
        ParticipantsModule,
        MATERIAL_MODULES,
    ],
    exports: [ExportContainers],
})
export class HeaderModule {}
