<form [formGroup]="form" class="table-edit">
    <table class="table-edit__table">
        <tbody formGroupName="table">
            <tr>
                <td></td>
                <td
                    *ngFor="
                        let cell of (table.controls[0] | asFormArray).controls;
                        let k = index
                    "
                >
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="columnMenu"
                        [matMenuTriggerData]="{ index: k }"
                    >
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                </td>
            </tr>
            <tr
                *ngFor="let row of table.controls; let i = index"
                [formGroupName]="i"
            >
                <td>
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="rowMenu"
                        [matMenuTriggerData]="{ index: i }"
                    >
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                </td>

                <td
                    *ngFor="
                        let cell of (row | asFormArray).controls;
                        let j = index
                    "
                >
                    <input
                        class="table-edit__input"
                        [attr.title]="'Cell ' + i + ':' + j"
                        [formControlName]="j"
                    />
                </td>
            </tr>
        </tbody>
    </table>
</form>

<mat-menu #rowMenu>
    <ng-template matMenuContent let-index="index">
        <button mat-menu-item (click)="insertRow(index)">
            <mat-icon>add</mat-icon>
            Add row above
        </button>

        <button mat-menu-item (click)="insertRow(index + 1)">
            <mat-icon>add</mat-icon>
            Add row below
        </button>

        <button mat-menu-item (click)="deleteRow(index)">
            <mat-icon>delete</mat-icon>
            Delete row
        </button>
    </ng-template>
</mat-menu>

<mat-menu #columnMenu>
    <ng-template matMenuContent let-index="index">
        <button mat-menu-item (click)="insertColumn(index)">
            <mat-icon>add</mat-icon>
            Add column before
        </button>

        <button mat-menu-item (click)="insertColumn(index + 1)">
            <mat-icon>add</mat-icon>
            Add column after
        </button>

        <button mat-menu-item (click)="deleteColumn(index)">
            <mat-icon>delete</mat-icon>
            Delete column
        </button>
    </ng-template>
</mat-menu>
