import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    ContentElementComponent,
    ListCardElementContext,
} from '@app/shared-modules/content-view/models';
import { ContentElement } from '@libs/models';

@Component({
    selector: 'app-list-card-element',
    templateUrl: './list-card-element.component.html',
    styleUrls: ['./list-card-element.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListCardElementComponent implements ContentElementComponent {
    @Input() element: ContentElement<ListCardElementContext>;
}
