import { Injectable } from '@angular/core';
import { LocalStorageKey } from '@libs/models';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
    get<T = string>(
        key: LocalStorageKey,
        shouldParse: boolean = false
    ): T | null {
        let storageValue: string;
        try {
            storageValue = localStorage.getItem(key);
        } catch (e) {
            throw new Error(
                '[LocalStorageService.get]: Could not access Local Storage'
            );
        }

        if (!storageValue) {
            return null;
        }

        return shouldParse
            ? (JSON.parse(storageValue) as T)
            : (storageValue as unknown as T);
    }

    set(
        key: LocalStorageKey,
        value: string | number | object | null | undefined
    ): void {
        if (value === null || value === undefined) {
            this.remove(key);
        }

        const stringValue =
            typeof value === 'string' ? value : JSON.stringify(value);

        try {
            localStorage.setItem(key, stringValue);
        } catch (e) {
            throw new Error(
                '[LocalStorageService.set]: Could not access Local Storage'
            );
        }
    }

    remove(key: LocalStorageKey): void {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            throw new Error(
                '[LocalStorageService.remove]: Could not access Local Storage'
            );
        }
    }
}
