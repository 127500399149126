import { ChatMessageGroup } from '@app/room/modules/chat/models';
import {
    addAppointmentSuccess,
    addChatMessage,
    markAllAsRead,
    setParticipants,
    setRoom,
    updateStateFromRemoteRoom,
} from '@app/room/store/actions/room.actions';
import {
    ContentNavigationParameters,
    Participant,
    Room,
    SyncedRemoteRoomState,
    VideoProviderType,
} from '@app/room/store/models';
import { PDFViewerConfig } from '@app/room/store/models/pdf-viewer-config.interface';
import { ActiveToolType } from '@libs/models';
import { Action, createReducer, on } from '@ngrx/store';

/**
 *  RoomState: Local Room State
 *    -> RemoteRoom  // fetched from server
 *    -> SyncedRemoteRoomState: // state which is synced via Websocket and persistent
 *    -> VideoState: // current video setup
 *    -> Content : local naviation state
 *
 *  I think this state strucutre could be refactored.
 */

export interface RoomState {
    // `state` from classroom database room
    syncedState?: SyncedRemoteRoomState;
    // one row in classroom database without `state`
    room?: Room;
    participants?: Participant[];
    chat: ChatMessageGroup[];
    error: string;
}

const initialState: RoomState = {
    error: '',
    room: null,
    participants: [],
    chat: [],
};

export function roomReducer(state: RoomState, action: Action): RoomState {
    return reducer(state, action);
}

const reducer = createReducer(
    initialState,
    on(setParticipants, (state, action) => ({
        ...state,
        participants: action.participants,
        error: '',
    })),
    on(updateStateFromRemoteRoom, (state, action) => ({
        ...state,
        syncedState: action.state,
    })),
    on(setRoom, (state, action) => ({
        ...state,
        room: action.room,
    })),
    on(addChatMessage, (state, { message }) => {
        const { chat: groups } = state;

        if (
            !groups.length ||
            groups[groups.length - 1].user.id !== message.user.id
        ) {
            const newGroup = {
                user: message.user,
                messages: [message],
            };

            return { ...state, chat: [...groups, newGroup] };
        }

        const updatedLastGroup = {
            ...groups[groups.length - 1],
            messages: [...groups[groups.length - 1].messages, message],
        };

        return {
            ...state,
            chat: [...groups.slice(0, -1), updatedLastGroup],
        };
    }),
    on(markAllAsRead, (state) => ({
        ...state,
        chat: state.chat.map((group) => {
            const messages = group.messages.map((message) => ({
                ...message,
                unread: false,
            }));

            return {
                ...group,
                messages,
            };
        }),
    })),
    on(addAppointmentSuccess, (state, action) => ({
        ...state,
        appointment: action.date as string,
    }))
);

export const getRoom = (state: RoomState): Room => state.room;

export const getRootCourseId = (state: RoomState): number =>
    state.room && state.room.rootCourseId;

export const getContentNavigation = (
    state: RoomState
): ContentNavigationParameters =>
    state.syncedState && state.syncedState.contentNavigation;

export const getActiveTool = (state: RoomState): ActiveToolType =>
    (state.syncedState && state.syncedState.activeTool) ||
    ActiveToolType.Content;

export const getParticipants = (state: RoomState) => state.participants;
export const getAppointment = (state: RoomState) =>
    state.syncedState.appointment;
export const getChat = (state: RoomState) => state.chat;

export const getRemoteRoomState = (state: RoomState) => state.syncedState;

export const getWebrtcProvider = (state: RoomState): VideoProviderType =>
    state.room && state.room.webrtcProvider;

export const getPDFViewerConfig = (state: RoomState): PDFViewerConfig =>
    state.syncedState && state.syncedState.pdf;
