<div class="classroom-navigation">
    <button
        mat-icon-button
        [matMenuTriggerFor]="participantsMenu"
        class="classroom-navigation__icon-button"
        [attr.aria-label]="'action.openOnlineUsersMenu' | translate"
    >
        <mat-icon
            [matBadge]="participantsCount$ | async"
            matBadgeColor="primary"
            matBadgePosition="below"
        >
            group
        </mat-icon>
    </button>

    <mat-menu
        #participantsMenu="matMenu"
        class="classroom-navigation__participants-menu mat-card"
        xPosition="before"
    >
        <app-participants></app-participants>
    </mat-menu>

    <button
        mat-icon-button
        (click)="openSettings()"
        class="classroom-navigation__icon-button"
        [attr.aria-label]="'action.openSettings' | translate"
    >
        <mat-icon>settings</mat-icon>
    </button>

    <button
        mat-icon-button
        class="classroom-navigation__icon-button"
        (click)="openHelp()"
        [attr.aria-label]="'action.openHelp' | translate"
    >
        <mat-icon>help_outline</mat-icon>
    </button>
</div>
