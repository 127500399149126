import { ErrorHandler, Injectable } from '@angular/core';

import * as Sentry from '@sentry/angular';
import { environment } from '@env';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    handleError(error: any): any {
        if (environment.sentryDSN) {
            Sentry.captureException(error.originalError || error);
        }
        throw error;
    }
}
