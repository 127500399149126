import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'trustUrl' })
export class TrustUrlPipe implements PipeTransform {
    constructor(private readonly sanitizer: DomSanitizer) {}

    transform(url: string): any {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
