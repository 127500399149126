import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { RouterModule } from '@angular/router';
import {
    AvatarModule,
    ParticipantsModule,
    SettingsModule,
} from '@app/shared-modules';
import { Components } from '@app/sidebar/components';
import { TranslateModule } from '@ngx-translate/core';
import { Containers } from './containers';

@NgModule({
    declarations: [Containers, Components],
    imports: [
        CommonModule,
        AvatarModule,
        SettingsModule,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        MatListModule,
        ParticipantsModule,
        TranslateModule,
        RouterModule,
    ],
    exports: [Containers],
})
export class SidebarModule {}
