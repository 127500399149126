import { Component, Input } from '@angular/core';
import {
    ContentElementComponent,
    ListElementContext,
} from '@app/shared-modules/content-view/models';
import { ContentElement } from '@libs/models';

@Component({
    selector: 'app-list-element',
    templateUrl: './list-element.component.html',
    styleUrls: ['./list-element.component.scss'],
})
export class ListElementComponent implements ContentElementComponent {
    @Input()
    element: ContentElement<ListElementContext>;
}
