<h1 class="title-element">
    <span
        *ngFor="let word of words; let i = index; let last = last"
        class="title-element__word"
        [ngClass]="{ 'title-element__word--painted': i >= startColorFromIdx }"
    >
        {{ word }}

        <!-- space between words -->
        <ng-container *ngIf="!last"> </ng-container>
    </span>
</h1>
