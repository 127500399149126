import { SidenavLink } from '@app/room/models/sidenav-link.interface';
import { ActiveToolType } from '@libs/models';

export const SidenavLinks: SidenavLink[] = [
    {
        active: false,
        label: 'conference.menu.sliders',
        type: ActiveToolType.Content,
        icon: 'photo_library',
    },
    {
        active: false,
        label: 'conference.menu.board',
        type: ActiveToolType.Board,
        icon: 'palette',
    },
    {
        active: false,
        label: 'conference.menu.texteditor',
        type: ActiveToolType.Editor,
        icon: 'folder',
    },
    {
        active: false,
        label: 'pdfViewer.label',
        type: ActiveToolType.PDF,
        icon: 'description',
    },
];
