import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { TimerStatus, TimerValue } from '@app/shared-modules/timer/models';
import { objectsEqual } from '@libs/helpers';

@UntilDestroy()
@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit, OnDestroy {
    @Input()
    set time(time: number) {
        this.maximumTime = time;
        this.setTimeLeft(time);
    }

    @Input()
    autostart: boolean;

    @Input()
    set value(value: TimerValue) {
        const valuesExist = value && this._value;

        if (!valuesExist || objectsEqual(this._value, value)) {
            return;
        }

        /**
         * By using that `difference` I'm going to fix network lag if it exists
         */
        // this division produce decimal hell, but we don't care much about precision here
        const difference = Number.parseFloat(
            ((Date.now() - value.timestamp) / 1000).toFixed(0)
        );

        this.setTimeLeft(value.timeLeft - difference);
        this.setStatus(value.status);
    }

    @Output()
    readonly valueChanged = new EventEmitter<TimerValue>();

    timeLeft: number;

    progress: number;

    status$ = new BehaviorSubject<TimerStatus>(TimerStatus.stopped);

    private maximumTime: number;

    private _value: TimerValue;

    private interval: ReturnType<typeof setTimeout>;

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.status$
            .pipe(untilDestroyed(this))
            .subscribe((status) => this.handleStatusChange(status));

        if (this.autostart) {
            this.start();
        }
    }

    ngOnDestroy(): void {
        this.stop();
    }

    start(): void {
        this.setStatus(TimerStatus.started);
    }

    stop(): void {
        this.setStatus(TimerStatus.stopped);
    }

    private handleStatusChange(status: TimerStatus): void {
        this.emitValue(status);

        switch (status) {
            case TimerStatus.started:
                this.startInterval();
                break;
            case TimerStatus.stopped:
                this.stopInterval();
                break;
            default:
                throw new Error(`Unhandled timer status: ${status}`);
        }
    }

    private startInterval(): void {
        this.interval = setInterval(() => {
            this.doStep();
        }, 1000);
    }

    private stopInterval(): void {
        clearInterval(this.interval);
    }

    private doStep(): void {
        this.setTimeLeft(this.timeLeft - 1);

        if (this.timeLeft === 0) {
            this.stopInterval();
        }

        this.changeDetectorRef.detectChanges();
    }

    private setStatus(status: TimerStatus): void {
        this.status$.next(status);
    }

    private setTimeLeft(value: number): void {
        this.timeLeft = value;
        this.progress = (value / this.maximumTime) * 100;
    }

    private emitValue(status: TimerStatus): void {
        this._value = {
            status,
            timestamp: Date.now(),
            timeLeft: this.timeLeft,
        };

        this.valueChanged.emit(this._value);
    }
}
