import { ViewModeType } from '@libs/models';
import { createAction, props, union } from '@ngrx/store';
import { GlobalParameters } from '@app/core/store/models';

export enum SettingsActionType {
    setGlobalParameters = '[Settings] SetGlobalParameters',
    setMode = '[Settings] Set Room Mode',
    loadGlobalParameters = '[Settings] Load local settings from localStorage',
}

export const setGlobalParameters = createAction(
    SettingsActionType.setGlobalParameters,
    props<GlobalParameters>()
);

export const setMode = createAction(
    SettingsActionType.setMode,
    props<{ mode: ViewModeType }>()
);

export const loadGlobalParameters = createAction(
    SettingsActionType.loadGlobalParameters
);

const All = union({
    setGlobalParameters,
    setMode,
    loadGlobalParameters,
});

export type SettingsActionUnion = typeof All;
