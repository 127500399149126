import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    AspectCardElementContext,
    ContentElementComponent,
} from '@app/shared-modules/content-view/models';
import { ContentElement } from '@libs/models';

@Component({
    selector: 'app-aspect-card-element',
    templateUrl: './aspect-card-element.component.html',
    styleUrls: ['./aspect-card-element.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AspectCardElementComponent implements ContentElementComponent {
    @Input() element: ContentElement<AspectCardElementContext>;
}
