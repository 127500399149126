<div class="mentorscript-element">
    <div
        class="mentorscript-element__icon-wrapper icon-wrapper"
        *ngIf="!isBubble"
    >
        <div
            class="mentorscript-element__icon bg"
            *ngIf="context.type"
            [matTooltip]="'tools.mentorscript.hints.' + hintSuffix | translate"
            [ngClass]="color"
        >
            <mat-icon color="secondary">{{ icon }}</mat-icon>
        </div>
    </div>
    <div class="mentorscript-element__text" [ngClass]="{ bubble: isBubble }">
        <span
            class="demo-2 body"
            *ngIf="context.body"
            [innerHTML]="context.body"
        ></span>
    </div>
</div>
