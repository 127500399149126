import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewChild,
} from '@angular/core';
import { TimerComponent } from '@app/shared-modules/timer/containers/timer/timer.component';
import { TimerValue } from '@app/shared-modules/timer/models';
import {
    ContentElementComponent,
    SyncedElement,
    SyncedElementComponent,
    TimerElementContext,
} from '@app/shared-modules/content-view/models';

@Component({
    selector: 'app-timer-element',
    templateUrl: './timer-element.component.html',
    styleUrls: ['./timer-element.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimerElementComponent
    extends SyncedElementComponent<
        SyncedElement<TimerElementContext>,
        TimerValue
    >
    implements ContentElementComponent
{
    @Input() element: SyncedElement<TimerElementContext>;

    @ViewChild(TimerComponent)
    timerComponent;

    onChange(value: TimerValue): void {
        this.updateValue(value);
        this.broadcastValue(value);
    }

    protected hasChanges(value: TimerValue): boolean {
        return (
            this.value.timeLeft !== value.timeLeft ||
            this.value.status !== value.status
        );
    }
}
