<input
    hidden
    (change)="onFileChange($event)"
    #fileInput
    [accept]="accept"
    type="file"
/>

<button
    mat-flat-button
    [color]="color"
    (click)="fileInput.click()"
    type="button"
>
    {{ 'action.chooseFile' | translate }}
</button>

<p *ngIf="maxSizeMB" class="file-input__max-size-hint">
    {{ 'fileInput.maxSizeText' | translate: { mb: maxSizeMB } }}
</p>
