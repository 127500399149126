<div *ngIf="element?.context" class="input-element">
    <mat-form-field>
        <mat-label [textContent]="element.context.label"></mat-label>
        <textarea
            matInput
            cdkTextareaAutosize
            [placeholder]="element.context.placeholder"
            [ngModel]="value"
            (ngModelChange)="onChange($event)"
        ></textarea>
        <mat-hint *ngIf="element.context.hint">{{
            element.context.hint
        }}</mat-hint>
    </mat-form-field>
</div>
