<div class="list-card-element">
    <div class="list-card-element__header">
        {{ element.context.title }}
    </div>

    <section *ngIf="element.context.elements">
        <div
            *ngFor="let text of element.context.elements"
            class="list-card-element__content-element"
        >
            {{ text }}
        </div>
    </section>
</div>
