<div class="controls">
    <app-end-call-button
        *ngIf="displayEndCallButton$ | async"
        class="controls__end-call-button"
    ></app-end-call-button>

    <div class="controls__navigation">
        <app-admin-navigation
            *ngIf="isEditor$ | async; else userMenu"
        ></app-admin-navigation>

        <ng-template #userMenu>
            <app-classroom-navigation></app-classroom-navigation>
        </ng-template>

        <div class="controls__avatar-container">
            <app-avatar
                [src]="avatar$ | async"
                size="36"
                aria-hidden="true"
            ></app-avatar>
        </div>
    </div>

    <button
        mat-icon-button
        class="controls__sidebar-toggle"
        (click)="toggleSidebar()"
        [attr.aria-label]="'action.toggleSidebar' | translate"
    >
        <mat-icon>menu</mat-icon>
    </button>
</div>
