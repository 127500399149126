import { Injectable } from '@angular/core';
import {
    MatLegacySnackBar as MatSnackBar,
    MatLegacySnackBarConfig as MatSnackBarConfig,
} from '@angular/material/legacy-snack-bar';
import { GetMillisecondsFrom } from '@libs/helpers';

@Injectable({ providedIn: 'root' })
export class NotificationService {
    private readonly defaultConfig: MatSnackBarConfig = {
        duration: GetMillisecondsFrom.seconds(5),
    };

    constructor(private matSnackBar: MatSnackBar) {}

    create(message: string, action: string, config?: MatSnackBarConfig) {
        const snackBar = this.matSnackBar.open(
            message,
            action,
            config || this.defaultConfig
        );

        return snackBar.onAction();
    }
}
