/**
 * Active tool describes the tool
 * which is used at the moment (e.g. Board, Help etc.)
 */
export enum ActiveToolType {
    Content = 'content',
    Board = 'board',
    Editor = 'editor',
    EndSession = 'endsession',
    PDF = 'pdf',
}
