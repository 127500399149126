import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

declare type MediaControllerType = 'audio' | 'video';

export class MediaController {
    deviceForStreaming$: Observable<string>;

    isRecording$: Observable<boolean>;

    devices$: Observable<MediaDeviceInfo[]>;

    get isRecording(): boolean {
        return this.isRecording$$.value;
    }

    get deviceId(): string | null {
        return this.deviceId$$.value;
    }

    private isRecording$$ = new BehaviorSubject<boolean>(true);

    private deviceId$$ = new BehaviorSubject<string>(null);

    constructor(
        devices$: Observable<MediaDeviceInfo[]>,
        type: MediaControllerType
    ) {
        this.initDevices(devices$, type);
        this.initObservables();
    }

    setIsRecording(status: boolean) {
        this.isRecording$$.next(status);
    }

    setDeviceId(id: string) {
        this.deviceId$$.next(id);
    }

    private initDevices(
        devices$: Observable<MediaDeviceInfo[]>,
        type: MediaControllerType
    ) {
        const deviceKind: MediaDeviceKind =
            type === 'audio' ? 'audioinput' : 'videoinput';

        this.devices$ = devices$.pipe(
            filter((devices) => !!devices),
            map((devices) =>
                devices.filter((device) => device.kind === deviceKind)
            )
        );
    }

    private initObservables() {
        this.isRecording$ = this.isRecording$$.asObservable();

        this.deviceForStreaming$ = combineLatest([
            this.deviceId$$.asObservable(),
            this.isRecording$,
            this.devices$,
        ]).pipe(
            map(([deviceId, isRecording, devices]) => {
                const readyForStreaming = !!(
                    isRecording &&
                    devices &&
                    devices.length &&
                    deviceId &&
                    devices.find((device) => device.deviceId === deviceId)
                );

                return readyForStreaming ? deviceId : null;
            }),
            distinctUntilChanged()
        );
    }
}
