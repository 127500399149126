import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    sidebarOpen$: Observable<unknown>;

    private sidebarOpenSrc$ = new Subject();

    constructor() {
        this.sidebarOpen$ = this.sidebarOpenSrc$.asObservable();
    }

    open(): void {
        this.sidebarOpenSrc$.next(null);
    }
}
