/**
 * ViewMode
 * OR 'Mode' (old name)
 * describes if it's currently preview or demo view.
 */
export enum ViewModeType {
    Default = 'default',
    Preview = 'preview',
    Edit = 'edit',
    Demo = 'demo',
    Othertool = 'othertool',
    Inperson = 'inperson',
}
