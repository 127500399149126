import { Component, Input } from '@angular/core';
import { TimelinePoint } from '../../models';

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent {
    @Input()
    points: TimelinePoint[];

    @Input()
    currentIndex: number;
}
