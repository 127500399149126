import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViewModeType } from '@libs/models';
import { Store } from '@ngrx/store';
import * as fromCore from '../store';
import { RouterDataHelperService } from './router-data-helper.service';

@Injectable({
    providedIn: 'root',
})
export class UIService {
    constructor(
        private readonly store: Store<fromCore.CoreState>,
        private readonly routerDataHelperService: RouterDataHelperService
    ) {}

    get displayEndCallButton$(): Observable<boolean> {
        const appropriateMode$ = this.store
            .select(fromCore.selectMode)
            .pipe(
                map((mode) =>
                    [
                        ViewModeType.Default,
                        ViewModeType.Othertool,
                        ViewModeType.Inperson,
                    ].includes(mode)
                )
            );
        const appropriateRoute$ = this.routerDataHelperService.data$.pipe(
            map((data) => !!data.displayEndCallButton)
        );

        return combineLatest([appropriateMode$, appropriateRoute$]).pipe(
            map(
                ([appropriateMode, appropriateRoute]) =>
                    appropriateMode && appropriateRoute
            )
        );
    }
}
