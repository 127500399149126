import { BreadcrumbsLink } from '@app/header/models';
import {
    ContentLectureNavigationType,
    ContentNavigationParameters,
    ContentViewModeType,
} from '@app/room/store/models';
import { Course } from '@libs/models';
import {
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';

import { get } from 'lodash';

import * as fromContent from './reducers/content.reducers';
import * as fromRoom from './reducers/room.reducers';

export interface ConferenceState {
    content: fromContent.ContentState;
    room: fromRoom.RoomState;
}

export const reducers: ActionReducerMap<ConferenceState> = {
    content: fromContent.contentReducer,
    room: fromRoom.roomReducer,
};

// Selectors

export const selectConferenceState =
    createFeatureSelector<ConferenceState>('conference');

// ---------------------------------
// RoomState selectors
// ---------------------------------

export const selectRoomState = createSelector(
    selectConferenceState,
    (state) => state.room
);

export const getRoom = createSelector(selectRoomState, fromRoom.getRoom);

export const selectRootCourseId = createSelector(
    selectRoomState,
    fromRoom.getRootCourseId
);

export const getContentNavigation = createSelector(
    selectRoomState,
    fromRoom.getContentNavigation
);
export const getActiveTool = createSelector(
    selectRoomState,
    fromRoom.getActiveTool
);

export const getParticipants = createSelector(
    selectRoomState,
    fromRoom.getParticipants
);
export const getAppointment = createSelector(
    selectRoomState,
    fromRoom.getAppointment
);

export const getChat = createSelector(selectRoomState, fromRoom.getChat);

export const getRemoteRoomState = createSelector(
    selectRoomState,
    fromRoom.getRemoteRoomState
);

export const selectWebrtcProvider = createSelector(
    selectRoomState,
    fromRoom.getWebrtcProvider
);

export const getPDFViewerConfig = createSelector(
    selectRoomState,
    fromRoom.getPDFViewerConfig
);

// ---------------------------------
// ContentState selectors
// ---------------------------------

export const selectContentState = createSelector(
    selectConferenceState,
    (state) => state.content
);

export const getCourse = createSelector(selectContentState, (state, props) =>
    fromContent.getCourse(state, props)
);

export const getLecture = createSelector(selectContentState, (state, props) =>
    fromContent.getLecture(state, props)
);

export const getSlide = createSelector(selectContentState, (state, props) =>
    fromContent.getSlide(state, props)
);

export const getDownloadedCourses = createSelector(
    selectContentState,
    fromContent.getDownloadedCourses
);

export const getDownloadedLectures = createSelector(
    selectContentState,
    fromContent.getDownloadedLectures
);

// --------------------------------
// Selectors requiring Room and Content State
// --------------------------------

export const getBreadcrumbs = createSelector(
    selectRoomState,
    selectContentState,
    (roomState, contentState): BreadcrumbsLink[] => {
        let list: BreadcrumbsLink[] = [];

        const contentNavigation: ContentNavigationParameters =
            fromRoom.getContentNavigation(roomState);

        if (
            !contentNavigation ||
            contentNavigation.contentViewMode === ContentViewModeType.Home
        ) {
            return [
                {
                    id: -1,
                    title: 'Home',
                    type: ContentViewModeType.Home,
                },
            ];
        }

        const isCurrentlyOnCourse =
            contentNavigation.contentViewMode === ContentViewModeType.Course;
        const isCurrentlyOnLecture =
            contentNavigation.contentViewMode === ContentViewModeType.Lecture;

        // courses;
        if (!contentNavigation.currentCourseId) {
            throw new Error('Missing current course ID');
        }

        const coursesPart: BreadcrumbsLink[] = contentState.parentCourses.map(
            (course: Course) => {
                return {
                    title: course.title,
                    id: course.id,
                    type: ContentViewModeType.Course,
                };
            }
        );

        list = [...coursesPart];

        if (isCurrentlyOnCourse) {
            return list;
        }

        // lectures
        const currentLecture = fromContent.getLecture(
            contentState,
            contentNavigation.currentLectureId
        );
        if (currentLecture) {
            list.push({
                id: currentLecture.id,
                title: currentLecture.title,
                type: ContentViewModeType.Lecture,
            });
        }

        if (isCurrentlyOnLecture) {
            return list;
        }

        // slide
        const currentSlide = contentState.slides.find(
            (slide) => slide.id === contentNavigation.currentSlideId
        );
        if (currentSlide) {
            list.push({
                id: currentSlide.id,
                title: currentSlide.title,
                type: ContentViewModeType.Slide,
            });
        }

        return list;
    }
);

export const getCourseNavigation = createSelector(
    selectRoomState,
    selectContentState,
    (roomState, contentState): ContentLectureNavigationType => {
        const currentSlideId = get(
            roomState,
            'syncedState.contentNavigation.currentSlideId'
        );
        const { slides } = contentState;

        if (!currentSlideId || !slides) {
            return {
                currentSlideIndex: 0,
                totalSlides: 0,
            };
        }

        const currentSlideIndex = slides.findIndex(
            (slide) => slide.id === currentSlideId
        );
        return {
            currentSlideIndex,
            nextSlide: slides[currentSlideIndex + 1]
                ? slides[currentSlideIndex + 1]
                : null,
            prevSlide: slides[currentSlideIndex - 1]
                ? slides[currentSlideIndex - 1]
                : null,
            totalSlides: slides.length,
        };
    }
);
