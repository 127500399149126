import { Component, Input } from '@angular/core';
import {
    AudioElementContext,
    ContentElementComponent,
} from '@app/shared-modules/content-view/models';
import { ContentElement } from '@libs/models';

@Component({
    selector: 'app-audio-element',
    templateUrl: './audio-element.component.html',
    styleUrls: ['./audio-element.component.scss'],
})
export class AudioElementComponent implements ContentElementComponent {
    @Input() set element(element: ContentElement<AudioElementContext>) {
        if (!element || !element.context) {
            return;
        }

        this.src = element.context.src;
    }

    src: string;
}
