<div class="quote-element">
    <section class="quote-element__header">
        <div class="quote-element__icon"></div>
        <div class="quote-element__icon"></div>

        <p class="quote-element__author">
            {{ element.context.author }}
        </p>
    </section>

    <p
        class="quote-element__text"
        [ngClass]="{
            'quote-element__text--uppercased': element.context.isTextUppercased
        }"
    >
        {{ element.context.text }}
    </p>
</div>
