<p mat-dialog-title>Debug</p>

<ng-container *ngIf="debugData$ | async as data">
    <mat-dialog-content>
        <p class="debug-dialog__description">
            You can copy to clipboard debug data here
        </p>

        <div class="debug-dialog__data-wrapper">
            <pre>{{ data | json }}</pre>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button [mat-dialog-close]="true">Close</button>

        <button mat-flat-button color="primary" (click)="copyData(data)">
            Copy to clipboard
        </button>
    </mat-dialog-actions>
</ng-container>
