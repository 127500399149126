import { Component, Input } from '@angular/core';

declare type InfoCardType = 'success' | 'warning' | 'error';

@Component({
    selector: 'app-info-card',
    templateUrl: './info-card.component.html',
    styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent {
    @Input()
    title: string;

    @Input()
    icon: string;

    @Input()
    toggleAble = false;

    @Input()
    set type(type: InfoCardType) {
        this.cssClass = `info-card info-card--${type}`;
    }

    cssClass: string;

    isOpen = true;
}
