import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    ContentElementComponent,
    MentorscriptElement,
    MentorscriptElementContext,
    MentorScriptElementType,
} from '@app/shared-modules/content-view/models';

@Component({
    selector: 'app-mentorscript-element',
    templateUrl: './mentorscript-element.component.html',
    styleUrls: ['./mentorscript-element.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentorscriptElementComponent implements ContentElementComponent {
    @Input() set element(element: MentorscriptElement) {
        this.context = element.context;

        /**
         * some mentorscript elements has no type. It should fix that
         * by setting default one.
         */
        const type = this.context.type || MentorScriptElementType.Text;

        this.hintSuffix = type.toLowerCase();
        this.icon = this.getIcon(type);
        this.color = this.getColor(type);
        this.isBubble = type === MentorScriptElementType.Say;
    }

    context: MentorscriptElementContext;

    icon: string;

    color: string;

    hintSuffix: string;

    isBubble: boolean;

    getIcon(type: MentorScriptElementType): string {
        switch (type) {
            case MentorScriptElementType.Warning:
                return 'priority_high';
            case MentorScriptElementType.Say:
                return 'chat_bubble';
            case MentorScriptElementType.Tip:
                return 'grade';
            case MentorScriptElementType.Text:
                return '';
            default:
                return '';
        }
    }

    getColor(type: MentorScriptElementType): string {
        switch (type) {
            case MentorScriptElementType.Warning:
                return 'mat-warn mentorscript-element__icon--colored';
            case MentorScriptElementType.Do:
                return 'mat-info mentorscript-element__icon--colored';
            case MentorScriptElementType.Say:
                return '';
            case MentorScriptElementType.Tip:
                return 'mat-secondary mentorscript-element__icon--colored';
            case MentorScriptElementType.Text:
                return '';

            default:
                return 'mat-secondaryGrey mentorscript-element__icon--colored';
        }
    }
}
