import { Component, Input } from '@angular/core';
import { BulletPointStyle } from '../../models';

declare type BulletPointType = 'image' | 'icon' | 'number';

@Component({
    selector: 'app-bullet-point',
    templateUrl: './bullet-point.component.html',
    styleUrls: ['./bullet-point.component.scss'],
})
export class BulletPointComponent {
    @Input()
    index: number;

    @Input()
    set style(style: BulletPointStyle) {
        this.type = this.getTypeFromStyle(style);
        this._style = style;
    }

    type: BulletPointType;

    _style: BulletPointStyle;

    private readonly imageStyles = [
        BulletPointStyle.QuestionMark,
        BulletPointStyle.Cloud,
        BulletPointStyle.Star,
    ];

    private getTypeFromStyle(style: BulletPointStyle): BulletPointType {
        if (style === BulletPointStyle.Number) return 'number';

        return this.imageStyles.includes(style) ? 'image' : 'icon';
    }
}
