import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'app-global-spinner',
    templateUrl: './global-spinner.container.html',
    styleUrls: ['./global-spinner.container.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSpinnerContainer {
    @Input()
    public title: string;
}
