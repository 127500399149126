<mat-toolbar class="header">
    <div class="header__logo">
        <img height="25px" src="assets/img/vv-logo.svg" />
    </div>

    <div class="header__title-wrapper">
        <h1 class="header__title">{{ 'classroom.title' | translate }}</h1>
    </div>

    <div class="header__controls">
        <app-controls *ngIf="displayControls$ | async"></app-controls>
    </div>
</mat-toolbar>
