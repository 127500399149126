import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
    @Input()
    width = '150px';

    @Input()
    height = '150px';

    @Input()
    src: string;
}
