import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromCore from '@app/core/store';
import { catchError, filter, timeout } from 'rxjs/operators';
import { ErrorService } from '@app/core/services';
import { ErrorType } from '@app/error/models';

@Injectable({
    providedIn: 'root',
})
export class IsAuthenticatedGuard implements CanActivate {
    constructor(
        private readonly store: Store,
        private readonly errorService: ErrorService
    ) {}

    canActivate(): Observable<boolean | UrlTree> {
        return this.store.select(fromCore.selectIsAuthenticated).pipe(
            filter((isAuthenticated) => !!isAuthenticated),
            timeout(10000),
            catchError(() => {
                this.errorService.handleFatalError(ErrorType.authentication, {
                    reason: 'timeout',
                });

                return of(null);
            })
        );
    }
}
