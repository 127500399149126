export enum ContentElementType {
    SlideImgComponent = 'SlideImgComponent',
    Title = 'Title',
    MultilineTitle = 'MultilineTitle',
    ContentCard = 'ContentCard',
    ListCard = 'ListCard',
    AspectCard = 'AspectCard',
    Quote = 'Quote',
    List = 'List',
    SlideTextComponent = 'SlideTextComponent',
    Slider = 'Slider',
    Input = 'Input',
    Timer = 'Timer',
    Accordion = 'Accordion',
    Timeline = 'Timeline',
    SlideOembedComponent = 'SlideOembedComponent',
    SlideVideoComponent = 'SlideVideoComponent',
    SlideAudioComponent = 'SlideAudioComponent',
    Table = 'Table',

    // for mentorscript
    AlertNoteComponent = 'AlertNoteComponent',
}
