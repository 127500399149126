import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-number-bullet-point',
    templateUrl: './number-bullet-point.component.html',
    styleUrls: ['./number-bullet-point.component.scss'],
})
export class NumberBulletPointComponent {
    @Input()
    number: number;
}
