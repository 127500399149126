import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-timer-countdown',
    templateUrl: './timer-countdown.component.html',
    styleUrls: ['./timer-countdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimerCountdownComponent {
    @Input()
    set time(time: number) {
        const minutes = Math.floor(time / 60);
        const seconds = time - minutes * 60;
        this.timeString = this.buildTimeString(minutes, seconds);
    }

    timeString: string;

    private toString(num: number): string {
        const str = num.toString();
        return str.length < 2 ? `0${str}` : str;
    }

    private buildTimeString(minutes: number, seconds: number): string {
        const minutesStr = this.toString(minutes);
        const secondsStr = this.toString(seconds);

        return `${minutesStr}:${secondsStr}`;
    }
}
