import { Injectable } from '@angular/core';
import { ConnectionLevel } from '@app/room/modules/video-call/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoomProvidersModule } from '@app/room/room-providers.module';

@Injectable({
    providedIn: RoomProvidersModule,
})
export class ConnectionTrackerService {
    userHasBadConnection$: Observable<boolean>;

    partnerHasBadConnection$: Observable<boolean>;

    partnerConnectionLevel$: Observable<ConnectionLevel>;

    private userHasBadConnection$$ = new BehaviorSubject(false);

    private partnerHasBadConnection$$ = new BehaviorSubject(false);

    constructor() {
        this.userHasBadConnection$ = this.userHasBadConnection$$.asObservable();
        this.partnerHasBadConnection$ =
            this.partnerHasBadConnection$$.asObservable();
        this.partnerConnectionLevel$ = this.partnerHasBadConnection$.pipe(
            map((isBadConnection) =>
                isBadConnection
                    ? ConnectionLevel.Bad
                    : ConnectionLevel.Excellent
            )
        );
    }

    setUserHasBadConnection(status: boolean): void {
        this.userHasBadConnection$$.next(status);
    }

    setPartnerHasBadConnection(status: boolean): void {
        this.partnerHasBadConnection$$.next(status);
    }
}
