import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
} from '@angular/forms';

const DEFAULT_DATA: string[][] = [
    [null, null],
    [null, null],
];

@Component({
    selector: 'app-table-edit',
    templateUrl: './table-edit.component.html',
    styleUrls: ['./table-edit.component.scss'],
})
export class TableEditComponent {
    @Input()
    set data(data: string[][]) {
        this.buildForm(data || DEFAULT_DATA);
    }

    @Output()
    readonly formReady = new EventEmitter<UntypedFormArray>();

    form: UntypedFormGroup;

    table: UntypedFormArray;

    constructor(private readonly formBuilder: UntypedFormBuilder) {}

    insertRow(index: number): void {
        const columnNumber = (this.table.controls[0] as UntypedFormArray)
            .controls.length;
        const cells = this.createArray(columnNumber).fill(null);
        const cellControls = cells.map((cell) =>
            this.formBuilder.control(cell)
        );
        const row = this.formBuilder.array(cellControls);

        this.table.insert(index, row);
    }

    deleteRow(index: number): void {
        this.table.removeAt(index);
    }

    insertColumn(index: number): void {
        this.table.controls.forEach((control: UntypedFormArray) =>
            control.insert(index, this.formBuilder.control(null))
        );
    }

    deleteColumn(index: number): void {
        this.table.controls.forEach((control: UntypedFormArray) =>
            control.removeAt(index)
        );
    }

    private buildForm(data: string[][]): void {
        const rowArrays = data.map((row) => {
            const cellControls = row.map((cell) =>
                this.formBuilder.control(cell)
            );
            return this.formBuilder.array(cellControls);
        });

        this.table = this.formBuilder.array(rowArrays);

        this.form = this.formBuilder.group({
            table: this.table,
        });

        this.formReady.emit(this.table);
    }

    private createArray(n: number): null[] {
        return Array(n);
    }
}
