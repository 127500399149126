import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormArray } from '@angular/forms';

/**
 * This pipe's purpose is to resolve compiler problem, which happens when
 * FormArray elements are FormArray-s.
 *
 * E.g. we have such one.
 * For using in in two dimensional loop with *ngFor it expects AbstractControl-s
 * in `formArray.controls`. And it's not possible to use it like
 * `formArray.controls[0].controls` in template. Compiler says
 * that AbstractControl doesn't have `controls` property.
 */
@Pipe({
    name: 'asFormArray',
})
export class AsFormArrayPipe implements PipeTransform {
    transform(value: AbstractControl): UntypedFormArray {
        return value as UntypedFormArray;
    }
}
