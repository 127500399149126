<div class="content-card-element">
    <section class="content-card-element__header-row">
        <div>
            <p class="content-card-element__title">
                {{ element.context.title }}
            </p>

            <p class="content-card-element__subtitle">
                {{ element.context.subtitle }}
            </p>
        </div>

        <div class="content-card-element__index">
            <app-index [index]="element.context.index"></app-index>
        </div>
    </section>

    <div
        *ngIf="element.context.content"
        class="content-card-element__content ck-content"
        [innerHTML]="element.context.content"
    ></div>
</div>
