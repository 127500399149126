import { Injectable } from '@angular/core';

import { CanActivate } from '@angular/router';
import * as fromCore from '@app/core/store';

import { Store } from '@ngrx/store';
import { iif, Observable, of } from 'rxjs';
import { map, skipWhile, switchMap, withLatestFrom } from 'rxjs/operators';

/**
 * This guard checks if the user has role Editor or Admin,
 * if not – redirects to not-found.
 *
 * This complicated request fixes the case when user comes to classroom with direct link or does page reload.
 * 1. isAuthenticated === false on app start so we need to check if auth details saved in store
 * 2. waitUntil isAuthenticated becomes true (failed auth handles by auth initiator)
 * 3. then check if user has appropriate role
 */
@Injectable({
    providedIn: 'root',
})
export class EditorGuard implements CanActivate {
    constructor(private readonly store: Store<fromCore.CoreState>) {}

    canActivate(): Observable<boolean> {
        return this.store.select(fromCore.selectGlobalParameters).pipe(
            map(
                ({ externalRoomId, mode, token }) =>
                    !!(externalRoomId && mode && token)
            ),
            switchMap((hasAuthData) =>
                iif(
                    () => hasAuthData,
                    this.store.select(fromCore.selectIsAuthenticated).pipe(
                        skipWhile((isAuthenticated) => !isAuthenticated),
                        withLatestFrom(
                            this.store.select(fromCore.selectIsEditor)
                        ),
                        map(([_, isEditor]) => isEditor)
                    ),
                    of(false)
                )
            )
        );
    }
}
