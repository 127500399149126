import { Injectable } from '@angular/core';
import { DebugProvidersModule } from '@app/shared-modules/debug/debug-providers.module';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DebugDialogComponent } from '@app/shared-modules/debug/dialogs/debug-dialog/debug-dialog.component';

@Injectable({ providedIn: DebugProvidersModule })
export class DebugService {
    constructor(private readonly matDialog: MatDialog) {}

    openDialog() {
        this.matDialog.open(DebugDialogComponent);
    }
}
