<div class="sidebar">
    <ng-container *ngIf="displayControls$ | async">
        <div
            *ngIf="userProfile$ | async as userProfile"
            class="sidebar__header"
        >
            <app-avatar [src]="userProfile.avatar" size="50"></app-avatar>
        </div>

        <ng-container *ngIf="isEditor$ | async; else regularUserMenu">
            <mat-divider class="sidebar__divider"></mat-divider>

            <app-sidebar-button
                [path]="['room']"
                class="sidebar__button"
                [label]="'label.room' | translate"
                (click)="close()"
            ></app-sidebar-button>

            <app-sidebar-button
                [routerLink]="['admin', 'lectures']"
                class="sidebar__button"
                [label]="'label.lectures' | translate"
                (click)="close()"
            ></app-sidebar-button>

            <app-sidebar-button
                [routerLink]="['admin', 'courses']"
                class="sidebar__button"
                [label]="'label.courses' | translate"
                (click)="close()"
            ></app-sidebar-button>
        </ng-container>

        <mat-divider class="sidebar__divider"></mat-divider>

        <ng-template #regularUserMenu>
            <app-sidebar-user-controls
                (closeSidebar)="close()"
            ></app-sidebar-user-controls>
        </ng-template>
    </ng-container>
</div>
