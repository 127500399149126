import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Pipes } from '@app/shared-modules/trust-url/pipes';

@NgModule({
    declarations: [Pipes],
    exports: [Pipes],
    imports: [CommonModule],
})
export class TrustUrlModule {}
