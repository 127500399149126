/**
 * It should fix issue with `height: 100vh` for mobile WebKit
 */
export function fixWebKitHeight(): void {
    const updateVH = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    updateVH();
    window.addEventListener('resize', updateVH);
}
