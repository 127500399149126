import { GlobalParameters } from '@app/core/store/models';
import { SettingsState } from '@app/core/store/reducers/settings.reducers';
import { ViewModeType } from '@libs/models';

export const getLanguage = (state: SettingsState) =>
    state.globalParameters.lang;
export const getError = (state: SettingsState) => state.error;

export const getGlobalParameters = (state: SettingsState): GlobalParameters =>
    state.globalParameters || { debug: false };

export const getMode = (state: SettingsState): ViewModeType =>
    state.globalParameters.mode;

export const getExternalRoomId = (state: SettingsState): string =>
    state.globalParameters.externalRoomId;

export const getTestedState = (state: SettingsState) => state.tested;
