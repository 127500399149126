import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    ContentElementComponent,
    TimelineElementContext,
} from '@app/shared-modules/content-view/models';
import { ContentElement } from '@libs/models';

@Component({
    selector: 'app-timeline-element',
    templateUrl: './timeline-element.component.html',
    styleUrls: ['./timeline-element.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineElementComponent implements ContentElementComponent {
    @Input() element: ContentElement<TimelineElementContext>;
}
