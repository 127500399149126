import { Component, OnInit } from '@angular/core';
import * as fromCore from '@app/core/store';
import { SettingsActions } from '@app/core/store/actions';

import { RoomActions } from '@app/room/store/actions';

import { SlideInOut } from '@libs/helpers/animations';
import { LanguageType, ViewModeType } from '@libs/models';

import { Store } from '@ngrx/store';

import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-settings-dialog',
    templateUrl: './settings.dialog.html',
    styleUrls: ['./settings.dialog.scss'],
    animations: [SlideInOut],
})
export class SettingsDialog implements OnInit {
    currentLang: LanguageType;

    isDefaultMode$: Observable<boolean>;

    constructor(
        private store: Store<fromCore.CoreState>,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.isDefaultMode$ = this.store
            .select(fromCore.selectMode)
            .pipe(map((mode) => mode === ViewModeType.Default));

        this.currentLang = this.translateService.currentLang as LanguageType;
    }

    setVideoEnabled(value: boolean): void {
        const mode = value ? ViewModeType.Default : ViewModeType.Othertool;
        this.store.dispatch(SettingsActions.setMode({ mode }));
    }

    reloadRoom(): void {
        this.store.dispatch(RoomActions.updateRoomReloadAll());
    }

    setLang(lang: LanguageType): void {
        if (this.currentLang === lang) {
            return;
        }

        this.currentLang = lang;
        this.store.dispatch(SettingsActions.setGlobalParameters({ lang }));
    }
}
