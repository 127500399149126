import { Injectable, Optional } from '@angular/core';

import {
    AuthService,
    ErrorService,
    LocalStorageService,
} from '@app/core/services';
import {
    CoreState,
    selectUrl,
    selectGlobalParameters,
    selectMode,
} from '@app/core/store';
import { SettingsActions } from '@app/core/store/actions';
import {
    AuthActionsUnion,
    AuthActionType,
    authenticateByToken,
    authenticateFailure,
    authenticateSuccess,
    checkForRedirect,
    logout,
} from '@app/core/store/actions/auth.actions';
import { GlobalParameters, UserProfile } from '@app/core/store/models';
import { ErrorType } from '@app/error/models';
import { SentryService } from '@app/sentry';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { combineLatest, of, throwError } from 'rxjs';
import {
    catchError,
    map,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { ViewModeType } from '@libs/models';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffects {
    //
    // This procedure is responsible to:
    //  1) process Login from auth form
    //  2) is successful, save token to setGlobalParameters
    //
    authenticateByCredentials$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionType.authenticateByCredentials),
            switchMap((action) =>
                this.authService.login(action.email, action.password).pipe(
                    catchError((error) => {
                        authenticateFailure(error);

                        return throwError(error);
                    })
                )
            ),
            switchMap((response) => {
                const globalParameters: GlobalParameters = {
                    token: response.access_token,
                };

                return [
                    SettingsActions.setGlobalParameters(globalParameters),
                    authenticateByToken(),
                ];
            })
        )
    );

    //
    // This method runs if the token is set (see above)
    // then we run the authenticate procedure to connect to socket io.
    // on success, it will set the local user profile
    //

    authenticateByToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionType.authenticateByToken),
            withLatestFrom(this.store.select(selectGlobalParameters)),
            switchMap(([_, { token }]) => {
                if (!token) {
                    return throwError({
                        message: '[Auth] Missed auth token',
                    });
                }

                return this.authService.me();
            }),
            switchMap((userProfile: UserProfile) => [
                checkForRedirect({ userProfile }),
                authenticateSuccess({ userProfile }),
            ]),
            catchError((err) => of(authenticateFailure(err)))
        )
    );

    checkForRedirect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(checkForRedirect),
                map(({ userProfile }) => userProfile),
                withLatestFrom(
                    combineLatest([
                        this.store.select(selectMode),
                        this.store.select(selectUrl),
                    ])
                ),
                tap(([userProfile, [mode, url]]) => {
                    const isEditor =
                        userProfile.roles.includes('admin') ||
                        userProfile.roles.includes('editor');

                    if (!isEditor && mode === ViewModeType.Preview) {
                        this.router.navigate(['/', 'room']);
                        return;
                    }

                    if (isEditor && !url.match(/admin/)) {
                        this.router.navigate(['/', 'admin']);
                    }
                })
            ),
        { dispatch: false }
    );

    authenticateSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(authenticateSuccess),
                tap(({ userProfile }) => {
                    if (this.sentryService) {
                        this.sentryService.setUser(userProfile);
                    }
                })
            ),
        { dispatch: false }
    );

    authenticateFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(authenticateFailure),
                tap((error) => {
                    this.errorService.handleFatalError(
                        ErrorType.authentication,
                        error
                    );
                })
            ),
        { dispatch: false }
    );

    logout$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(logout),
                tap(() => {
                    this.localStorageService.remove('globalParameters');
                })
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions<AuthActionsUnion>,
        private readonly authService: AuthService,
        private readonly store: Store<CoreState>,
        private readonly localStorageService: LocalStorageService,
        private readonly errorService: ErrorService,
        private readonly router: Router,
        @Optional() private readonly sentryService: SentryService
    ) {}
}
