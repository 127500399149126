<div
    class="slide-preview"
    [ngClass]="{
        'slide-preview--first-slide': isFirstSlide,
        'slide-preview--v1': lectureVersion === LectureVersion.v1
    }"
>
    <ng-container *ngIf="isFirstSlide">
        <img
            height="43px"
            width="100px"
            class="slide-preview__logo"
            src="assets/img/vv-logo-dark.png"
        />
    </ng-container>

    <div class="slide-preview__content">
        <app-content-view
            [content]="content"
            [version]="lectureVersion"
        ></app-content-view>
    </div>
</div>
