import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TimerStatus } from '@app/shared-modules/timer/models';

@Component({
    selector: 'app-timer-actions',
    templateUrl: './timer-actions.component.html',
    styleUrls: ['./timer-actions.component.scss'],
})
export class TimerActionsComponent {
    @Input()
    set status(status: TimerStatus) {
        this.isStarted = status === TimerStatus.started;
    }

    @Output()
    readonly started = new EventEmitter();

    @Output()
    readonly stopped = new EventEmitter();

    isStarted = false;

    onStart(): void {
        this.started.emit();
    }

    onStop(): void {
        this.stopped.emit();
    }
}
