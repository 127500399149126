import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { SentryErrorHandler } from './sentry.error-handler';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: ErrorHandler, useClass: SentryErrorHandler },
    ],
})
export class SentryModule {}
