import { Injectable } from '@angular/core';
import { UserProfile } from '@app/core/store/models';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

export interface LoginSuccessHttpResonse {
    access_token: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private apiService: ApiService) {}

    login(
        username: string,
        password: string
    ): Observable<LoginSuccessHttpResonse> {
        const payload = {
            username,
            password,
            client_id: '1',
            grant_type: 'password',
            client_secret: 'non-secret',
            scope: '*',
        };
        return this.apiService.postCoreSystem(`/api/v2/auth`, payload);
    }

    me(): Observable<UserProfile> {
        return this.apiService
            .getFromAPI<{ data: UserProfile }>(`/api/v2/users/me`)
            .pipe(map(({ data }) => data));
    }
}
