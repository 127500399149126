<div class="slide-oembed">
    <div class="slide-oembed__content">
        <div *ngIf="!hasVideo">
            <p>Video information is missing</p>
            <pre>{{ _element.context | json }}</pre>
        </div>

        <iframe
            *ngIf="hasVideo"
            width="100%"
            height="100%"
            frameborder="0"
            [src]="_element.context?.url | trustUrl"
            allow="autoplay; fullscreen"
            allowfullscreen
        ></iframe>
    </div>
</div>
