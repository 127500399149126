<ng-container *ngIf="participants$ | async as participants">
    <div class="participants" (click)="$event.stopPropagation()">
        <h4 class="participants__title mat-h4 mat-primaryGrey-text">
            {{ 'navbar.participants' | translate }}
        </h4>

        <app-participant
            *ngFor="let participant of participants"
            [participant]="participant"
        ></app-participant>
    </div>
</ng-container>
