<ng-container [ngSwitch]="type">
    <app-number-bullet-point
        *ngSwitchCase="'number'"
        [number]="index"
    ></app-number-bullet-point>

    <app-icon-bullet-point
        *ngSwitchCase="'icon'"
        [style]="_style"
    ></app-icon-bullet-point>

    <app-image-bullet-point
        *ngSwitchCase="'image'"
        [style]="_style"
    ></app-image-bullet-point>
</ng-container>
