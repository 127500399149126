import { Component } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-debug-dialog',
    templateUrl: './debug-dialog.component.html',
    styleUrls: ['./debug-dialog.component.scss'],
})
export class DebugDialogComponent {
    debugData$ = this.store.select((state) => state);

    constructor(
        private clipboard: Clipboard,
        private matSnackBar: MatSnackBar,
        private store: Store,
        private dialogRef: MatDialogRef<DebugDialogComponent>
    ) {}

    copyData(data: any) {
        this.clipboard.copy(JSON.stringify(data));
        this.matSnackBar.open('Copied to clipboard', 'Close', {
            duration: 5000,
        });
        this.dialogRef.close();
    }
}
