<div class="image-element" [ngClass]="class">
    <div class="image-element__wrapper">
        <img
            class="image-element__image"
            [src]="context.src"
            [alt]="context.alternativeText ? context.alternativeText : ''"
        />
        <div class="image-element__decoration-wrapper"></div>
    </div>
    <div class="image-element__outside-decoration-wrapper"></div>
</div>
