import {
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { TimepickerComponent } from '@app/shared-modules/timepicker/components/timepicker/timepicker.component';

@Component({
    selector: 'app-timepicker-slide',
    template: `<ng-content></ng-content>`,
    // tslint:disable-next-line:use-component-view-encapsulation
    encapsulation: ViewEncapsulation.None,
})
export class TimepickerSlideComponent implements OnInit {
    /** Is current slide active */
    @HostBinding('class.active')
    @Input()
    active: boolean;

    @HostBinding('class.animated') animated = false;

    @HostBinding('class.carousel-item-next') directionNext = false;

    @HostBinding('class.carousel-item-left') directionLeft = false;

    @HostBinding('class.carousel-item-prev') directionPrev = false;

    @HostBinding('class.carousel-item-right') directionRight = false;

    /** Wraps element by appropriate CSS classes */
    @HostBinding('class.carousel-item')

    /** Link to Parent(container-collection) component */
    // public carousel: CarouselComponent;
    // public el: ElementRef = null;
    el: ElementRef | any = null;

    constructor(public carousel: TimepickerComponent, el: ElementRef) {
        this.carousel = carousel;
        this.el = el;
    }

    /** Fires changes in container collection after adding a new slide instance */
    ngOnInit(): void {
        this.carousel.addSlide(this);
    }
}
