import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { TimepickerSlideComponent } from '@app/shared-modules/timepicker/components/timepicker-slide/timepicker-slide.component';
import {
    Carousel,
    TIMEPICKER_RANGES,
} from '@app/shared-modules/timepicker/models';

@Component({
    selector: 'app-timepicker',
    templateUrl: './timepicker.component.html',
    styleUrls: ['./timepicker.component.scss'],
    // tslint:disable-next-line:use-component-view-encapsulation
    encapsulation: ViewEncapsulation.None,
})
export class TimepickerComponent implements AfterViewInit {
    @Input()
    events: any[];

    carousel: Carousel = new Carousel();

    selectedTimeIndex: number;

    @Output()
    readonly timeChanged = new EventEmitter<string>();

    protected _currentActiveSlide: number | any;

    protected _slides: TimepickerSlideComponent[] = [];

    protected el: ElementRef | any = null;

    protected timeToSlide = 300;

    protected width = 0;

    readonly ranges = TIMEPICKER_RANGES;

    label = this.ranges[0].range;

    constructor(el: ElementRef) {
        this.el = el;
    }

    addSlide(slide: TimepickerSlideComponent): void {
        this.carousel.items.push(slide);
        this.carousel.totalItems = this.carousel.items.length;
        this.setLabel(this.carousel);
    }

    ngAfterViewInit(): void {
        if (this.carousel.totalItems) {
            this.width =
                this.el.nativeElement.querySelector(
                    '.carousel-wrapper'
                ).offsetWidth;
            this.el.nativeElement
                .querySelectorAll('.carousel-item')
                .forEach((el) => {
                    // eslint-disable-next-line no-param-reassign
                    el.style.width = `${this.width}px`;
                });
        }
    }

    slideNext(): void {
        if (this.checkLimitsCarousel(this.carousel.activeIndex + 1)) {
            this.moveSlideTo(this.carousel.activeIndex + 1);
        }
    }

    slidePrev(): void {
        if (this.checkLimitsCarousel(this.carousel.activeIndex - 1)) {
            this.moveSlideTo(this.carousel.activeIndex - 1);
            // setTimeout(() => { this.carousel.activeIndex =  this.carousel.activeIndex - 1 });
        }
    }

    setLabel(carousel: Carousel): void {
        this.label = this.ranges[carousel.activeIndex].range;
    }

    // todo: it should be calculated on every events change once instead of on every time period
    // timeParse(time: string): boolean {
    //     let ret;
    //
    //     this.events.forEach((mins) => {
    //         if (ret !== false) {
    //             const t = moment()
    //                 .startOf('day')
    //                 .add(mins.startMinuteOfDay, 'minutes')
    //                 .format('HH:mm');
    //             ret = t !== time;
    //         }
    //     });
    //
    //     return typeof ret === 'boolean' ? ret : true;
    // }

    selectTime(index: number, time: string): void {
        this.selectedTimeIndex = index;
        this.timeChanged.emit(time);
    }

    private moveSlideTo(index: number): void {
        this.carousel.activeIndex = index;
        this.moveCarousel(this.width * index, this.timeToSlide);
    }

    private checkLimitsCarousel(index: number): boolean {
        return (
            this.carousel.activeIndex !== index &&
            index >= 0 &&
            index < this.carousel.totalItems
        );
    }

    private moveCarousel(deg: number, timeTransform: number = 0): void {
        this.el.nativeElement.querySelector(
            '.carousel-inner'
        ).style.transition = `transform ${timeTransform}ms`;
        this.el.nativeElement.querySelector(
            '.carousel-inner'
        ).style.webkitTransition = `transform ${timeTransform}ms`;
        this.setTransformCarousel(-deg);
    }

    private setTransformCarousel(deg: number): void {
        this.el.nativeElement.querySelector(
            '.carousel-inner'
        ).style.transform = `translateX(${deg}px)`;
        this.el.nativeElement.querySelector(
            '.carousel-inner'
        ).style.webkitTransform = `translateX(${deg}px)`;
        this.setLabel(this.carousel);
    }
}
