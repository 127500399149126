import {
    Participant,
    Room,
    SyncedRemoteRoomState,
} from '@app/room/store/models';
import { ChatMessage } from '@app/room/modules/chat/models';
import { PDFViewerConfig } from '@app/room/store/models/pdf-viewer-config.interface';
import { ActiveToolType } from '@libs/models';
import { createAction, props, union } from '@ngrx/store';
import { FirepadMode } from '@app/room/modules/documents/models';

export enum RoomActionType {
    UpdateSyncedRoomState = '[Room] Update Remote Room State (sending to Server)',

    UpdateStateFromRemoteRoom = '[Room] Update Remote Room (received from Server)',
    SetRoom = '[Room] Set Room From Remote',

    NavigateToHome = '[Room] Navigating to Home',
    NavigateToLecture = '[Room] Navigate to Lecture',
    NavigateToCourse = '[Room] Navigate to Course',
    NavigateToSlide = '[Room] Navigate to Slide',

    UpdateRoomReloadAll = '[Room] Sending reload request',

    ChangeTool = '[Room] Change current tool',
    SetParticipants = '[Room] Set Participants Data',

    AddChatMessage = '[Chat] Add Chat Message',
    MarkAllAsRead = '[Chat] Mark all messages as read',

    FirepadSetMode = '[Firepad] Set Mode',
    FirepadNavigateOpenDocument = '[Firepad] Opening document',

    AddAppointment = '[Room] Set appointment date',
    AddAppointmentSuccess = '[Room] Set appointment date success',
    DeleteAppointment = '[Room] Delete appointment',
    DeleteAppointmentSuccess = '[Room] Delete appointment success',

    UpdatePDFViewerConfig = '[Room] Update PDF Viewer config',
    ResetPDFViewerConfig = '[Room] Reset PDF Viewer config',

    SwitchToFeedback = '[Room] Switch to feedback',
    EndCall = '[Room] End call',
}

export const updateSyncedRoomState = createAction(
    RoomActionType.UpdateSyncedRoomState,
    props<{ state: SyncedRemoteRoomState }>()
);

export const addChatMessage = createAction(
    RoomActionType.AddChatMessage,
    props<{ message: ChatMessage }>()
);

export const markAllAsRead = createAction(RoomActionType.MarkAllAsRead);

export const updateStateFromRemoteRoom = createAction(
    RoomActionType.UpdateStateFromRemoteRoom,
    props<{ state: SyncedRemoteRoomState }>()
);

export const setRoom = createAction(
    RoomActionType.SetRoom,
    props<{ room: Room }>()
);

export const setParticipants = createAction(
    RoomActionType.SetParticipants,
    props<{ participants: Participant[] }>()
);

export const navigateToHome = createAction(RoomActionType.NavigateToHome);

export const navigateToCourse = createAction(
    RoomActionType.NavigateToCourse,
    props<{ id: number }>()
);

export const navigateToLecture = createAction(
    RoomActionType.NavigateToLecture,
    props<{ id: number }>()
);

export const navigateToSlide = createAction(
    RoomActionType.NavigateToSlide,
    props<{ id: number }>()
);

export const updateRoomReloadAll = createAction(
    RoomActionType.UpdateRoomReloadAll
);

export const changeTool = createAction(
    RoomActionType.ChangeTool,
    props<{ tool: ActiveToolType }>()
);

export const firepadSetMode = createAction(
    RoomActionType.FirepadSetMode,
    props<{ mode: FirepadMode }>()
);

export const firepadNavigateOpenDocument = createAction(
    RoomActionType.FirepadNavigateOpenDocument,
    props<{ documentId: string }>()
);

export const addAppointment = createAction(
    RoomActionType.AddAppointment,
    props<{ payload: { externalRoomId: string; datetime: Date } }>()
);

export const addAppointmentSuccess = createAction(
    RoomActionType.AddAppointmentSuccess,
    props<{ date: string }>()
);

export const deleteAppointment = createAction(RoomActionType.DeleteAppointment);

export const deleteAppointmentSuccess = createAction(
    RoomActionType.DeleteAppointmentSuccess
);

export const updatePDFViewerConfig = createAction(
    RoomActionType.UpdatePDFViewerConfig,
    props<{ config: PDFViewerConfig }>()
);

export const resetPDFViewerConfig = createAction(
    RoomActionType.ResetPDFViewerConfig
);

export const switchToFeedback = createAction(RoomActionType.SwitchToFeedback);
export const endCall = createAction(RoomActionType.EndCall);

const all = union({
    updateSyncedRoomState,
    addChatMessage,
    markAllAsRead,
    updateStateFromRemoteRoom,
    setRoom,
    setParticipants,
    navigateToHome,
    navigateToCourse,
    navigateToLecture,
    navigateToSlide,
    updateRoomReloadAll,
    changeTool,
    firepadSetMode,
    firepadNavigateOpenDocument,
    addAppointment,
    addAppointmentSuccess,
    deleteAppointment,
    deleteAppointmentSuccess,
    updatePDFViewerConfig,
    resetPDFViewerConfig,
    switchToFeedback,
    endCall,
});

export type RoomActionsUnion = typeof all;
