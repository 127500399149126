import { Injectable } from '@angular/core';
import { ErrorType } from '@app/error/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import * as fromCore from '@app/core/store';
import { AuthActions } from '@app/core/store/actions';

import { environment } from '@env';

import { Observable, Subject } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { io } from 'socket.io-client';
import {
    BroadcastPacket,
    BroadcastPacketType,
    SocketEvent,
    SocketServiceSettings,
} from '@app/room/models';
import { RoomProvidersModule } from '@app/room/room-providers.module';
import { ErrorService } from '@app/core/services/error.service';
import { RoomActions } from '../store/actions';
import { Participant, Room } from '../store/models';
import { ConnectionTrackerService } from './connection-tracker.service';

interface JoinedSuccessPacket {
    isDriver: boolean;
    userId: number;
    room: Room;
}

/* eslint-disable no-console */
@UntilDestroy()
@Injectable({
    providedIn: RoomProvidersModule,
})
export class SocketService {
    broadcast$: Observable<BroadcastPacket>;

    private host = environment.socketApi;

    // it's SocketIOClient.Socket, but I didn't find quick way to use that type
    private socket: any;

    private options: SocketServiceSettings;

    private broadcastSrc$: Subject<BroadcastPacket>;

    constructor(
        private readonly store: Store<fromCore.CoreState>,
        private readonly connectionTrackerService: ConnectionTrackerService,
        private readonly errorService: ErrorService
    ) {
        this.broadcastSrc$ = new Subject<BroadcastPacket>();
        this.broadcast$ = this.broadcastSrc$.asObservable();
    }

    connect(options: SocketServiceSettings): void {
        if (this.socket) {
            this.socket.connect();
            return;
        }
        this.options = options;
        if (
            !options.credentials.externalRoomId ||
            options.credentials.externalRoomId === ''
        ) {
            console.error(
                '[SocketIO] tried to connect to socektio without roomid',
                options
            );
            return;
        }
        try {
            this.socket = io(this.host);
            this.socket.connect();
            this.listening();
        } catch (e) {
            console.warn(e);
        }
    }

    disconnect(): void {
        this.socket.disconnect();
    }

    broadcast(data: BroadcastPacket): Observable<any> {
        return this.emit(SocketEvent.conferenceRoomBroadcast, data);
    }

    emit(event: SocketEvent, data?: any): Observable<any> {
        return new Observable<any>((observer) => {
            if (!this.socket) {
                throw new Error('socket is not connected!');
            }

            this.socket.emit(event, data, (response) => {
                if (response.success) {
                    observer.next(response.data);
                } else {
                    observer.error(response.data);
                }
                observer.complete();
            });
        });
    }

    on(event: string): Observable<any> {
        return new Observable<any>((observer) => {
            if (!this.socket) {
                console.error(
                    'tried to listen to a socket evnet before socket was ready',
                    event
                );
                return;
            }
            this.socket.off(event);
            this.socket.on(event, (data) => {
                observer.next(data);
            });
        });
    }

    private listening(): void {
        this.socket.on(SocketEvent.conferenceRoomBroadcast, (response) => {
            const { packet } = response;

            this.broadcastSrc$.next(packet);

            switch (packet.type) {
                case BroadcastPacketType.hardReload:
                    window.location.reload();
                    break;
                case BroadcastPacketType.endCall:
                    this.store.dispatch(RoomActions.switchToFeedback());
                    break;
                default:
                    break;
            }
        });

        this.socket.on('error', (error: string) => {
            console.warn(`ERROR: '${error}' (${this.host})`);
        });

        this.socket.on(SocketEvent.pleaseAuth, () => {
            this.socket.emit(
                SocketEvent.conferenceAuth,
                this.options.credentials
            );
        });

        this.socket.on(SocketEvent.notAuthenticated, () => {
            console.warn(`SERVER ERROR - not authenticated`);
            this.socket.emit(
                SocketEvent.conferenceAuth,
                this.options.credentials
            );
        });

        this.socket.on(
            SocketEvent.conferenceRoomJoinedSuccess,
            ({ room, isDriver }: JoinedSuccessPacket) => {
                this.store.dispatch(RoomActions.setRoom({ room }));
                this.store.dispatch(
                    AuthActions.setAuthDetails({
                        isDriver,
                    })
                );
            }
        );

        this.socket.on(SocketEvent.userJoinEvent, () => {
            this.connectionTrackerService.userHasBadConnection$
                .pipe(
                    take(1),
                    switchMap((status) =>
                        this.emit(SocketEvent.slowInternetConnectivity, {
                            status,
                        })
                    ),
                    untilDestroyed(this)
                )
                .subscribe();
        });

        this.socket.on(
            SocketEvent.conferenceRoomUsersOnline,
            (data: { sender: unknown; users: Participant[] }) => {
                const participants = data.users;

                this.store.dispatch(
                    RoomActions.setParticipants({ participants })
                );
            }
        );

        this.socket.on(SocketEvent.conferenceRoomStateUpdated, (data) => {
            if (!data || !data.state) {
                console.error(
                    'CONFERENCE_ROOM_STATE_UPDATED - invalid state update',
                    data
                );
                return;
            }
            this.store.dispatch(
                RoomActions.updateStateFromRemoteRoom({ state: data.state })
            );
        });

        this.socket.on(SocketEvent.userHelloFailEvent, (data) => {
            const errorData = {
                data,
                message: '[SOCKET] userHalloFailEvent',
            };

            this.errorService.handleFatalError(
                ErrorType.authentication,
                errorData
            );
        });

        this.socket.on(SocketEvent.shutdown, () => {
            this.errorService.handleFatalError(ErrorType.shutdown);
        });

        this.socket.on(SocketEvent.conferenceError, (data) => {
            const errorObject = {
                data,
                message: '[SOCKET] Conference error',
            };

            this.errorService.handleFatalError(ErrorType.unknown, errorObject);
        });

        this.socket.on(
            SocketEvent.badConnectionStatusChange,
            (data: { status: boolean; userId: number }) =>
                this.store
                    .select(fromCore.selectUserProfile)
                    .pipe(
                        filter((profile) => !!profile),
                        take(1),
                        untilDestroyed(this)
                    )
                    .subscribe((profile) => {
                        if (profile.id === data.userId) {
                            return;
                        }

                        this.connectionTrackerService.setPartnerHasBadConnection(
                            data.status
                        );
                    })
        );
    }
}
/* eslint-enable no-console */
