import { Component, Input } from '@angular/core';
import {
    SyncedElementComponent,
    SyncedElement,
    InputElementContext,
    ContentElementComponent,
} from '@app/shared-modules/content-view/models';

@Component({
    selector: 'app-input-element',
    templateUrl: './input-element.component.html',
    styleUrls: ['./input-element.component.scss'],
})
export class InputElementComponent
    extends SyncedElementComponent<SyncedElement<InputElementContext>, string>
    implements ContentElementComponent
{
    @Input() element: SyncedElement<InputElementContext>;

    timeout: ReturnType<typeof setTimeout>;

    pause = 2000; // ms

    onChange(value: string): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            const newValue = value.trim();
            this.updateValue(newValue);
            this.broadcastValue(newValue);
        }, this.pause);
    }
}
