import { Component, Input } from '@angular/core';
import { ContentElement } from '@libs/models';
import { ContentElementComponent } from '../../models';

@Component({
    selector: 'app-preview-element',
    templateUrl: './preview-element.component.html',
    styleUrls: ['./preview-element.component.scss'],
})
export class PreviewElementComponent implements ContentElementComponent {
    @Input()
    element: ContentElement<any>;
}
