<div *ngIf="element?.context as context" class="table-element">
    <div class="table-element__table-wrapper">
        <table
            class="table-element__table"
            [ngClass]="{
                'table-element__table--first-row-header':
                    context.isFirstRowHeader,
                'table-element__table--first-column-header':
                    context.isFirstColumnHeader
            }"
        >
            <tbody>
                <tr *ngFor="let row of context.table">
                    <td *ngFor="let cell of row">
                        <div class="table-element__cell-inner">
                            {{ cell }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <p class="table-element__text">
        {{ context.subtext }}
    </p>
</div>
