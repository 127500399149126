import { ContentElementType } from '@app/shared-modules/content-view/models/content-element-type.enum';

interface OptionGroup {
    name: string;
    options: ContentElementType[];
}

export const ElementTypeGroups: OptionGroup[] = [
    {
        name: 'Text',
        options: [
            ContentElementType.Title,
            ContentElementType.MultilineTitle,
            ContentElementType.SlideTextComponent,
        ],
    },
    {
        name: 'Styled',
        options: [
            ContentElementType.List,
            ContentElementType.Quote,
            ContentElementType.Table,
            ContentElementType.Timeline,
        ],
    },
    {
        name: 'Card',
        options: [
            ContentElementType.ContentCard,
            ContentElementType.AspectCard,
            ContentElementType.ListCard,
        ],
    },
    {
        name: 'Media',
        options: [
            ContentElementType.SlideImgComponent,
            ContentElementType.SlideOembedComponent,
            ContentElementType.SlideVideoComponent,
            ContentElementType.SlideAudioComponent,
        ],
    },
    {
        name: 'Synced',
        options: [
            ContentElementType.Slider,
            ContentElementType.Input,
            ContentElementType.Accordion,
        ],
    },
];
