import {
    MissingTranslationHandler,
    MissingTranslationHandlerParams,
} from '@ngx-translate/core';

export class CustomMissingTranslationHandler
    implements MissingTranslationHandler
{
    handle(params: MissingTranslationHandlerParams): string {
        // eslint-disable-next-line no-console
        console.warn('Missed Translation:', params.key);
        return params.key;
    }
}
