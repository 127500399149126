import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromCore from '@app/core/store';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class OnboardingPassedGuard implements CanActivate {
    constructor(
        private readonly store: Store,
        private readonly router: Router
    ) {}

    canActivate(): Observable<boolean | UrlTree> {
        return this.store.select(fromCore.selectIsAuthenticated).pipe(
            map((isAuthenticated) => {
                if (!isAuthenticated) {
                    this.router.navigate(['/', 'onboarding']);
                }

                return isAuthenticated;
            })
        );
    }
}
