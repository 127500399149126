import { Injectable } from '@angular/core';

import { CanActivate } from '@angular/router';
import * as fromCore from '@app/core/store';

import { AuthActions } from '@app/core/store/actions';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

/**
 * This guard checks if the user signed in,
 * if not initializes auth process
 */
@Injectable({
    providedIn: 'root',
})
export class AuthInitiatorGuard implements CanActivate {
    constructor(private readonly store: Store<fromCore.CoreState>) {}

    canActivate(): Observable<boolean> {
        return this.store.pipe(
            select(fromCore.selectIsAuthenticated),
            tap((isAuthenticated) => {
                if (!isAuthenticated) {
                    this.store.dispatch(AuthActions.authenticateByToken());
                }
            }),
            map(() => true)
        );
    }
}
