<div class="content-view" #container [ngClass]="'content-view--' + version">
    <div
        *ngFor="let row of content"
        class="content-view__row"
        [ngClass]="'content-view__row--' + row.verticalAlign"
    >
        <div *ngFor="let column of row.columns" class="content-view__column">
            <div *ngFor="let element of column" class="content-view__element">
                <app-dynamic-element
                    [element]="element"
                    [renderMedia]="renderMedia"
                ></app-dynamic-element>
            </div>
        </div>
    </div>
</div>
