import { Component } from '@angular/core';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as RoomState from '@app/room/store/conference.state';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { SettingsDialog } from '@app/shared-modules/settings/dialogs/settings/settings.dialog';
import { HelpContainer } from '@app/room/dialogs/help/help.container';

@Component({
    selector: 'app-classroom-navigation',
    templateUrl: './classroom-navigation.component.html',
    styleUrls: ['./classroom-navigation.component.scss'],
})
export class ClassroomNavigationComponent {
    participantsCount$ = this.store
        .select(RoomState.getParticipants)
        .pipe(map((participants) => participants.length.toString()));

    constructor(public dialog: MatDialog, private readonly store: Store) {}

    openHelp(): void {
        this.dialog.open(HelpContainer);
    }

    openSettings(): void {
        this.dialog.open(SettingsDialog, {
            width: '600px',
            panelClass: 'settings-modal',
        });
    }
}
