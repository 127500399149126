import { UserProfile } from '@app/core/store/models';
import { Action, createReducer, on } from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';

export interface AuthState {
    isAuthenticated: boolean | null;
    isDriver: boolean | null;
    userProfile: UserProfile | null;
}

export const initialAuthState: AuthState = {
    isAuthenticated: null,
    isDriver: null,
    userProfile: null,
};

const reducer = createReducer(
    initialAuthState,
    on(AuthActions.authenticateSuccess, (state, { userProfile }) => ({
        ...state,
        userProfile,
        isAuthenticated: true,
    })),
    on(AuthActions.setAuthDetails, (state, { isDriver }) => ({
        ...state,
        isDriver,
    })),
    on(AuthActions.setUserProfile, (state, { userProfile }) => ({
        ...state,
        userProfile,
    })),
    on(AuthActions.logout, () => initialAuthState)
);

export function authReducer(state: AuthState, action: Action): AuthState {
    return reducer(state, action);
}
