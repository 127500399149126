import {
    fetchParentCoursesSuccess,
    fetchCourseSuccess,
    fetchLectureSuccess,
} from '@app/room/store/actions/content.actions';
import { Course, Lecture, Slide } from '@libs/models';
import { Action, createReducer, on } from '@ngrx/store';

export interface ContentState {
    courses: Course[];
    lectures: Lecture[];
    // slides of last downloaded lecture
    slides: Slide[];
    // parents of last downloaded course
    parentCourses: Course[];
}

const initialContentState: ContentState = {
    courses: [],
    lectures: [],
    slides: [],
    parentCourses: [],
};

const reducer = createReducer(
    initialContentState,
    on(fetchCourseSuccess, (state, { course }) => ({
        ...state,
        courses: state.courses.includes(course)
            ? state.courses
            : [...state.courses, course],
    })),
    on(fetchLectureSuccess, (state, { lecture }) => {
        return {
            ...state,
            slides: lecture.slides,
            lectures: state.lectures.includes(lecture)
                ? state.lectures
                : [...state.lectures, lecture],
        };
    }),
    on(fetchParentCoursesSuccess, (state, { parentCourses }) => {
        return {
            ...state,
            parentCourses,
        };
    })
);

export function contentReducer(
    state: ContentState,
    action: Action
): ContentState {
    return reducer(state, action);
}

export const getCourse = (state: ContentState, id: number) =>
    state.courses.find((course) => course.id === id);

export const getLecture = (state: ContentState, id: number) => {
    return state.lectures.find((lecture) => lecture.id === id);
};

export const getSlide = (state: ContentState, id: number) => {
    return state.slides.find((slide) => slide.id === id);
};

export const getDownloadedCourses = (state: ContentState) => state.courses;
export const getDownloadedLectures = (state: ContentState) => state.lectures;
