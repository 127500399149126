import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from '@app/shared-modules/avatar/components';

@NgModule({
    declarations: [AvatarComponent],
    imports: [CommonModule],
    exports: [AvatarComponent],
})
export class AvatarModule {}
