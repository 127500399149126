import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { RouterDataHelperService } from '@app/core/services/router-data-helper.service';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SeoService {
    constructor(
        private readonly title: Title,
        private readonly meta: Meta,
        private readonly routerDataHelperService: RouterDataHelperService
    ) {}

    init() {
        this.routerDataHelperService.data$
            .pipe(
                map((data) => data.seo || {}),
                untilDestroyed(this)
            )
            .subscribe((seoData) => {
                const { title } = seoData;
                const { metaTags } = seoData;

                this.setTitle(title || 'Classroom');

                if (metaTags) {
                    this.setMetaTags(metaTags);
                }
            });
    }

    setTitle(title: string) {
        this.title.setTitle(title);
    }

    setMetaTags(metaTags: MetaDefinition[]) {
        metaTags.forEach((tag) => this.meta.updateTag(tag));
    }
}
