import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent {
    @Input()
    accept = '';

    @Input()
    color: ThemePalette = 'primary';

    /**
     * max file size (MegaBytes)
     */
    @Input()
    set maxSize(mb: number) {
        this.maxSizeMB = mb;
        this.maxSizeB = mb * 1024 * 1024;
    }

    maxSizeMB: number | null = null;

    maxSizeB: number | null = null;

    @Output()
    readonly selected = new EventEmitter<File>();

    constructor(
        private readonly translateService: TranslateService,
        private readonly matSnackBar: MatSnackBar
    ) {}

    onFileChange(event: any): void {
        const { files } = event.target;

        if (files.length <= 0) {
            return;
        }

        const file: File = files[0];

        if (this.maxSizeB && file.size > this.maxSizeB) {
            this.onFileSizeExceeded();
            return;
        }

        this.selected.emit(file);
    }

    private onFileSizeExceeded(): void {
        const message = this.translateService.instant(
            'fileInput.maxSizeExceededMessage',
            { limit: this.maxSizeMB }
        );
        const action = this.translateService.instant('action.close');

        this.matSnackBar.open(message, action, {
            duration: 5000,
        });
    }
}
