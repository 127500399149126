import { createAction, props, union } from '@ngrx/store';
import { UserProfile } from '@app/core/store/models';

export enum AuthActionType {
    authenticateByCredentials = '[Auth] Authenticate by credentials',
    authenticateByToken = '[Auth] Authenticate by token',
    checkForRedirect = '[Auth] Check for redirect',
    authenticateSuccess = '[Auth] Authenticate Success',
    authenticateFailure = '[Auth] Authenticate Failure',
    logout = '[Auth] Logout',
    setAuthDetails = '[Auth] Set auth details',
    setUserProfile = '[Auth] Set user profile',
    getUserProfile = '[Auth] Get user profile',
    getUserProfileSuccess = '[Auth] Get User Success',
    getUserProfileFailure = '[Auth] Get User Failure',
}

export const authenticateByCredentials = createAction(
    AuthActionType.authenticateByCredentials,
    props<{ email: string; password: string }>()
);

export const authenticateByToken = createAction(
    AuthActionType.authenticateByToken
);

export const checkForRedirect = createAction(
    AuthActionType.checkForRedirect,
    props<{ userProfile: UserProfile }>()
);

export const authenticateSuccess = createAction(
    AuthActionType.authenticateSuccess,
    props<{ userProfile: UserProfile }>()
);

export const authenticateFailure = createAction(
    AuthActionType.authenticateFailure,
    props<{ message: string; data: any }>()
);

export const logout = createAction(AuthActionType.logout);

export const setUserProfile = createAction(
    AuthActionType.setUserProfile,
    props<{ userProfile: UserProfile }>()
);

export const getUserProfile = createAction(AuthActionType.getUserProfile);

export const getUserProfileSuccess = createAction(
    AuthActionType.getUserProfileSuccess,
    props<{ userProfile: UserProfile }>()
);

export const getUserProfileFailure = createAction(
    AuthActionType.getUserProfileFailure
);

export const setAuthDetails = createAction(
    AuthActionType.setAuthDetails,
    props<{ isDriver: boolean }>()
);

const all = union({
    authenticateByCredentials,
    authenticateByToken,
    authenticateSuccess,
    logout,
    setUserProfile,
    getUserProfile,
    getUserProfileSuccess,
    getUserProfileFailure,
    setAuthDetails,
});

export type AuthActionsUnion = typeof all;
