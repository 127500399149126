import { UserProfile } from '@app/core/store/models';
import { AuthState } from '@app/core/store/reducers/auth.reducers';
import { UserRoles } from '@libs/models';

export const getIsAuthenticated = (state: AuthState): boolean =>
    state.isAuthenticated;

export const getUserProfile = (state: AuthState): UserProfile =>
    state.userProfile;

export const getIsAdmin = (state: AuthState): boolean =>
    state.userProfile?.roles.includes(UserRoles.admin);

export const getIsEditor = (state: AuthState): boolean =>
    state.userProfile?.roles.includes(UserRoles.admin) ||
    state.userProfile?.roles.includes(UserRoles.editor);

export const getIsDriver = (state: AuthState): boolean => state.isDriver;
