import { Injectable } from '@angular/core';
import { UserProfile } from '@app/core/store/models';
import { SentryModule } from '@app/sentry/sentry.module';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { environment } from '@env';
import { ActiveToolType } from '@libs/models';

@Injectable({
    providedIn: SentryModule,
})
export class SentryService {
    constructor() {}

    initSentry(): void {
        Sentry.init({
            dsn: environment.sentryDSN,
            integrations: [
                new Integrations.BrowserTracing({
                    tracingOrigins: [
                        'localhost',
                        'https://conference.services.volunteer-vision.com',
                        'https://conference.staging.volunteer-vision.com',
                    ],
                    routingInstrumentation: Sentry.routingInstrumentation,
                }),
            ],

            tracesSampleRate: 1.0,
            environment: environment.env,
        });
    }

    setUser({ id }: UserProfile): void {
        Sentry.setUser({ id: id.toString() });
    }

    setCurrentTool(currentTool: ActiveToolType): void {
        Sentry.setTag('current.tool', currentTool);
    }
}
