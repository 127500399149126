import { CommonModule } from '@angular/common';
/* Interceptors */
import {
    HTTP_INTERCEPTORS,
    HttpClientJsonpModule,
    HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
/* Redux */
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from 'environments/environment';

import { ToastrModule } from 'ngx-toastr';
/* Custom Interceptors */
import { TokenInterceptor } from './interceptors/token.interceptor';
/* Services */
/* Store */
import { reducers } from './store';
import { Effects } from './store/effects';

/**
 * Core module
 * where project shared service
 * should be located
 */
@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        HttpClientJsonpModule,

        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
        }),
        /**
         * StoreModule.forRoot is imported once in the root module, accepting a reducer
         * function or object map of reducer functions. If passed an object of
         * reducers, combineReducers will be run creating your application
         * meta-reducer. This returns all services for an @ngrx/store
         * based application.
         */
        StoreModule.forRoot(reducers),
        // StoreModule.forRoot(reducers, { metaReducers }),

        /**
         * @ngrx/router-store keeps router state up-to-date in the store.
         */
        StoreRouterConnectingModule.forRoot({
            /*
        They stateKey defines the name of the state used by the router-store reducer.
        This matches the key defined in the map of reducers
      */
            stateKey: 'router',
        }),
        EffectsModule.forRoot(Effects),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        // SocketIoModule.forRoot({
        //   url: environment.socketApi
        // })
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        // { provide: FunctionsRegionToken, useValue: 'europe-west1' }
    ],
})
export class CoreModule {}
