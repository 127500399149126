<h1 mat-dialog-title>{{ 'help.headline' | translate }}</h1>

<mat-dialog-content class="help">
    <section class="help__section">
        <p class="mat-h3">{{ 'help.title' | translate }}</p>

        <p class="mat-body-text mat-secondaryGrey">
            {{ 'help.subtitle' | translate }}
        </p>
    </section>

    <section class="help__section">
        <app-card>
            <app-card-header
                >{{ 'help.issues.title' | translate }}</app-card-header
            >
            <app-card-content>
                <mat-accordion>
                    <mat-expansion-panel
                        *ngFor="let item of helpArticles; let i = index"
                    >
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ 'help.issues.' + item + '.title' | translate
                                }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div
                            [innerHTML]="
                                'help.issues.' + item + '.body' | translate
                            "
                        ></div>
                    </mat-expansion-panel>
                </mat-accordion>
            </app-card-content>
        </app-card>
    </section>

    <section class="help__section">
        <app-card>
            <app-card-header>
                {{ 'help.other.title' | translate }}
            </app-card-header>

            <app-card-content>
                <div class="help__content-row">
                    <p>{{ 'help.othertool.explanation' | translate }}</p>

                    <a
                        class="help__stroked-button"
                        mat-stroked-button
                        color="primary"
                        (click)="turnOffVideo()"
                        matTooltip="{{ 'help.othertool.tooltip' | translate }}"
                    >
                        {{ 'help.othertool.disable' | translate }}
                    </a>
                </div>

                <div class="help__content-row">
                    <p>{{ 'help.teamviewer.explanation' | translate }}</p>

                    <a
                        class="help__stroked-button"
                        mat-stroked-button
                        color="primary"
                        [href]="teamviewerUrl"
                        target="_blank"
                        matTooltip="{{ 'help.teamviewer.tooltip' | translate }}"
                    >
                        {{ 'help.teamviewer.download' | translate }}
                    </a>
                </div>
            </app-card-content>
        </app-card>
    </section>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button color="primary" [mat-dialog-close]="true">
        {{'action.close'| translate}}
    </button>
</mat-dialog-actions>
