<div [ngClass]="cssClass">
    <div class="info-card__header">
        <div class="info-card__title">
            <mat-icon *ngIf="icon" class="info-card__icon">
                {{ icon }}
            </mat-icon>

            <p>{{ title }}</p>
        </div>

        <button
            *ngIf="toggleAble"
            mat-icon-button
            class="info-card__toggle"
            (click)="isOpen = !isOpen"
        >
            <mat-icon>
                {{ isOpen ? 'expand_less' : 'expand_more' }}
            </mat-icon>
        </button>
    </div>

    <ng-content *ngIf="isOpen"></ng-content>
</div>
