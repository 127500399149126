import { Injectable, Optional } from '@angular/core';
import { BroadcastPacketType } from '@app/room/models';
import { SyncedElementUpdatePacket } from '@app/shared-modules/content-view/models';
import { replaceArrayElement } from '@libs/helpers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { SocketService } from '@app/room/services/socket.service';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class SyncedElementsService {
    private storedValuesSrc$: BehaviorSubject<{ id: string; value: unknown }[]>;

    constructor(
        /*
            We display synced elements in admin panel, and socket service could
            be not provided at this moment
        */
        @Optional()
        private readonly socketService: SocketService,
        private readonly localStorageService: LocalStorageService
    ) {
        this.init();
    }

    init(): void {
        const storedValues =
            this.localStorageService.get<{ id: string; value: unknown }[]>(
                'syncedElements',
                true
            ) || [];

        this.storedValuesSrc$ = new BehaviorSubject<
            { id: string; value: unknown }[]
        >(storedValues);

        this.storedValuesSrc$
            .pipe(untilDestroyed(this))
            .subscribe((values) =>
                this.localStorageService.set('syncedElements', values)
            );
    }

    saveInLocalStorage<Value>({
        id,
        value,
    }: {
        id: string;
        value: Value;
    }): void {
        let storedValues = this.storedValuesSrc$.getValue();

        const storedElementIndex = storedValues.findIndex(
            (element) => element.id === id
        );

        if (storedElementIndex === -1) {
            storedValues.push({ id, value });
            return;
        }

        storedValues = replaceArrayElement(
            storedValues,
            { id, value },
            storedElementIndex
        );

        this.storedValuesSrc$.next(storedValues);
    }

    loadFromLocalStorage<T>(id: string): T | null {
        const storedValues = this.storedValuesSrc$.getValue();

        const targetElement = storedValues.find((element) => element.id === id);

        return targetElement ? (targetElement.value as T) : null;
    }

    getUpdatesByID<T>(id: string): Observable<T> {
        if (!this.socketService) {
            return of(null);
        }

        return this.socketService.broadcast$.pipe(
            filter(
                (packet) =>
                    packet.type === BroadcastPacketType.syncedElementUpdate
            ),
            filter((packet: SyncedElementUpdatePacket<T>) => packet.id === id),
            map((packet) => packet.value)
        );
    }

    update(update: SyncedElementUpdatePacket): Observable<unknown> {
        if (!this.socketService) {
            return of(null);
        }

        return this.socketService.broadcast(update);
    }
}
