import {
    Component,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output,
} from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
    @Input()
    public title = '';

    @Input()
    public grayBackground = false;

    @Input()
    public cancellable = false;

    @Input()
    public customCancelButtonText: string = undefined;

    @Output()
    readonly cancelled = new EventEmitter();

    onCancel(): void {
        this.cancelled.emit();
    }
}
