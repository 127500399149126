import { EnvironmentConfig } from './environment-config.interface';

export const environment: EnvironmentConfig = {
    env: 'staging',
    production: false,
    serviceWorkerEnabled: true,
    api: 'https://api.staging.volunteer-vision.com',
    platformUrl: 'https://app.staging.volunteer-vision.com',
    socketApi: 'https://classroom-api.staging.volunteer-vision.com',
    langApi:
        'https://api2.volunteer-vision.com/api/v2/translations/conference3',
    tokBox: {
        apiKey: '46224732',
    },
    sentryDSN:
        'https://3c555d4d16884c36be9d999e126b12a0@o110482.ingest.sentry.io/1416196',

    firebase: {
        apiKey: 'AIzaSyAQCQjefFC-q1tHcTBFyJK7cmQ6HgbTjJM',
        authDomain: 'vv-classroom-staging.firebaseapp.com',
        databaseURL:
            'https://vv-classroom-staging-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'vv-classroom-staging',
        storageBucket: 'vv-classroom-staging.appspot.com',
        messagingSenderId: '473213592475',
        appId: '1:473213592475:web:43ad2d44255472b379f73e',
        measurementId: 'G-5LMZZEHW4Q',
    },

    agora: {
        appId: 'Uafa8AmtEeyOAq8weo0MVw/ld2gfao2RofflA',
        region: 'gb-lon',
    },
};
