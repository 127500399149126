<div class="spinner" [ngClass]="{ 'spinner--gray-bg': grayBackground }">
    <h5 class="spinner__title" *ngIf="title?.length">
        {{ title }}
    </h5>

    <div class="spinner__container">
        <div class="spinner__circle"></div>
    </div>

    <button
        *ngIf="cancellable"
        mat-flat-button
        color="primary"
        class="spinner__cancel-button"
        (click)="onCancel()"
    >
        {{ customCancelButtonText || ('action.cancel' | translate) }}
    </button>
</div>
