import { Component, Input } from '@angular/core';
import { BulletPointStyle } from '../../models';

@Component({
    selector: 'app-image-bullet-point',
    templateUrl: './image-bullet-point.component.html',
    styleUrls: ['./image-bullet-point.component.scss'],
})
export class ImageBulletPointComponent {
    @Input()
    set style(style: BulletPointStyle) {
        const baseUrl = 'assets/img/bullet-point-icons/';

        switch (style) {
            case BulletPointStyle.QuestionMark:
                this.src = `${baseUrl}questionMarkBullet.png`;
                break;
            case BulletPointStyle.Star:
                this.src = `${baseUrl}StartIcon.png`;
                break;
            default:
                this.src = `${baseUrl}cloud.png`;
                break;
        }
    }

    src: string;
}
