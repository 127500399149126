import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { concat, interval } from 'rxjs';
import { GetMillisecondsFrom } from '@libs/helpers';
import { NotificationService } from '@app/core/services/notification.service';
import { environment } from '@env';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SwService {
    constructor(
        private swUpdate: SwUpdate,
        private appRef: ApplicationRef,
        private notificationService: NotificationService,
        private translateService: TranslateService
    ) {}

    init() {
        if (!environment.serviceWorkerEnabled) {
            return;
        }

        this.initUpdateListener();
        this.initUnrecoverableChecker();
        this.initUpdateChecker();
    }

    private initUpdateListener() {
        this.swUpdate.versionUpdates
            .pipe(untilDestroyed(this))
            .subscribe((event) => {
                if (event.type === 'VERSION_READY') {
                    const message =
                        this.translateService.instant('pwa.updateIsReady');
                    const action =
                        this.translateService.instant('action.update');

                    this.notificationService
                        .create(message, action, {})
                        .pipe(untilDestroyed(this))
                        .subscribe(async () => {
                            await this.swUpdate.activateUpdate();
                            document.location.reload();
                        });
                }

                if (event.type === 'VERSION_INSTALLATION_FAILED') {
                    // eslint-disable-next-line no-console
                    console.error(event.error, event.version.hash);
                    throw new Error('SW: Version installation failed');
                }
            });
    }

    private initUpdateChecker() {
        const appIsStable$ = this.appRef.isStable.pipe(
            first((isStable) => isStable === true)
        );
        const every30Min$ = interval(GetMillisecondsFrom.minutes(30));
        const every30MinOnceAppIsStable$ = concat(appIsStable$, every30Min$);

        every30MinOnceAppIsStable$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.swUpdate.checkForUpdate());
    }

    private initUnrecoverableChecker() {
        this.swUpdate.unrecoverable.pipe(untilDestroyed(this)).subscribe(() => {
            window.location.reload();
        });
    }
}
