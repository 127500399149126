import {
    animate,
    group,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

export const SlideRightInOut = [
    trigger('slideInOut', [
        state(
            'in',
            style({
                transform: 'translateY(-50%) translateX(0%)',
                opacity: '1',
                display: 'block',
                position: 'absolute',
            })
        ),
        state(
            'out',
            style({
                opacity: '0',
                display: 'none',
                transform: 'translateY(-50%) translateX(100%)',
                position: 'absolute',
            })
        ),

        transition('in => out', [
            group([
                animate(
                    '400ms ease-in-out',
                    style({
                        transform: 'translateY(-50%) translateX(100%)',
                        opacity: '0',
                    })
                ),
                animate(
                    '1ms ease-out',
                    style({
                        display: 'none',
                    })
                ),
            ]),
        ]),

        transition('out => in', [
            group([
                animate(
                    '400ms ease-in-out',
                    style({
                        transform: 'translateY(-50%) translateX(0%)',
                        opacity: '1',
                    })
                ),
            ]),
        ]),
    ]),
];
