import { Component, Input } from '@angular/core';
import { ContentElement } from '@libs/models';
import { ContentElementComponent } from '../../models';

@Component({
    selector: 'app-unknown-element',
    templateUrl: './unknown-element.component.html',
    styleUrls: ['./unknown-element.component.scss'],
})
export class UnknownElementComponent implements ContentElementComponent {
    @Input()
    element: ContentElement<any>;
}
