import { setGlobalParameters } from '@app/core/store/actions/settings.actions';
import { GlobalParameters } from '@app/core/store/models';
import { ErrorState } from '@app/error/models';
import { ViewModeType } from '@libs/models';
import { Action, createReducer, on } from '@ngrx/store';

export interface SettingsState {
    globalParameters: GlobalParameters;
    error: ErrorState | null;
    // todo: find better name
    tested: boolean;
}

export const initialSettingsState: SettingsState = {
    globalParameters: {},
    error: null,
    tested: false,
};

const reducer = createReducer(
    initialSettingsState,
    on(setGlobalParameters, (state, action) => {
        const globalParameters: GlobalParameters = {
            externalRoomId:
                action.externalRoomId || state.globalParameters.externalRoomId,
            mode:
                action.mode ||
                state.globalParameters.mode ||
                ViewModeType.Default,
            lang: action.lang || state.globalParameters.lang || 'en',
            token: action.token || state.globalParameters.token,
            debug: action.debug || state.globalParameters.debug,
        };

        try {
            localStorage.setItem(
                'globalParameters',
                JSON.stringify(globalParameters)
            );
        } catch {
            throw new Error(
                '[LocalStorageService.get]: Could not access Local Storage'
            );
        }

        return {
            ...state,
            globalParameters,
        };
    })
);

export function settingsReducer(
    state: SettingsState,
    action: Action
): SettingsState {
    return reducer(state, action);
}
