<div *ngIf="_participant" class="participant">
    <div class="participant__details">
        <div
            class="participant__avatar-container participant__avatar-container--online"
        >
            <app-avatar [src]="_participant.avatar" size="30"></app-avatar>
        </div>

        <div>
            <h4>{{ _participant.firstName }} {{ _participant.lastName }}</h4>
            <h5 class="participant__status">online</h5>
        </div>
    </div>

    <mat-icon
        class="participant__connection-level"
        [ngClass]="connectionLevelClass"
    >
        wifi
    </mat-icon>
</div>
