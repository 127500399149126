import {
    Component,
    HostListener,
    OnInit,
    Optional,
    ViewChild,
} from '@angular/core';
import { environment } from '@env';
import { MatSidenav } from '@angular/material/sidenav';
import {
    RouterDataHelperService,
    SeoService,
    SwService,
    TranslationInitializerService,
} from '@app/core/services';
import { SettingsActions } from '@app/core/store/actions';
import { SentryService } from '@app/sentry';
import { SidebarService } from '@app/sidebar/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as fromCore from '@app/core/store';
import { map } from 'rxjs/operators';
import { DebugService } from '@app/shared-modules/debug/services';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @ViewChild(MatSidenav)
    sidebar: MatSidenav;

    limitedHeight$ = this.routerDataHelperService.data$.pipe(
        map((data) => !!data.limitedPageHeight)
    );

    constructor(
        private readonly translationInitializerService: TranslationInitializerService,
        private readonly store: Store<fromCore.CoreState>,
        private readonly routerDataHelperService: RouterDataHelperService,
        private readonly sidebarService: SidebarService,
        private readonly seoService: SeoService,
        private readonly debugService: DebugService,
        private readonly swService: SwService,
        /*
            It is optional because we include SentryModule with SentryService
            only if environment config has Sentry DSN
        */
        @Optional() private sentryService: SentryService
    ) {}

    ngOnInit(): void {
        this.translationInitializerService.init();
        this.seoService.init();
        this.swService.init();
        this.store.dispatch(SettingsActions.loadGlobalParameters());

        this.sidebarService.sidebarOpen$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.openSidebar());

        if (this.sentryService) {
            this.sentryService.initSentry();
        }
    }

    openSidebar(): void {
        this.sidebar.open();
    }

    closeSidebar(): void {
        this.sidebar.close();
    }

    @HostListener('document:keydown.control.alt.f1', ['$event'])
    handleKeyboardEvent(): void {
        if (environment.production) {
            return;
        }

        this.debugService.openDialog();
    }
}
