import { Injectable } from '@angular/core';
import {
    MentorScriptElementType,
    Row,
} from '@app/shared-modules/content-view/models';
import { ContentElement, ContentEntityType, Slide } from '@libs/models';

function capitalize(targetString: string): string {
    return targetString.charAt(0).toUpperCase() + targetString.slice(1);
}

@Injectable({
    providedIn: 'root',
})
export class SlidesService {
    checkSlide(slide: Slide): Slide {
        let checkedSlide = this.checkSlideSpeakerNotes({ ...slide });
        checkedSlide = this.checkSlideType(checkedSlide);
        return this.checkSlideContent(checkedSlide);
    }

    private checkSlideSpeakerNotes(slide: Slide): Slide {
        if (!slide.speakerNotes) {
            return { ...slide, speakerNotes: [] };
        }

        /**
         * some notes have lowercase types, some have capitalized. This should unify format.
         */
        const speakerNotes = slide.speakerNotes.map((note) => {
            const context = {
                ...note.context,
                type: capitalize(
                    note.context.type || MentorScriptElementType.Text
                ) as MentorScriptElementType,
            };

            return { ...note, context };
        });

        return { ...slide, speakerNotes };
    }

    private checkSlideContent(slide: Slide): Slide {
        if (slide.content) {
            if (slide.content.length === 0) {
                return slide;
            }

            const isLegacyRowFormat = Array.isArray(slide.content[0]);
            if (!isLegacyRowFormat) {
                return slide;
            }

            return {
                ...slide,
                content: this.serializeLegacyContent(
                    slide.content as unknown as ContentElement[][][]
                ),
            };
        }

        const elements = slide.elements || [];
        return {
            ...slide,
            content: [{ columns: [elements], verticalAlign: 'center' }],
        };
    }

    /**
     * We have some slides with `type === null`, we also have some checks
     * `if el.type === 'slide'`, so we need to add type to ones without it.
     */
    private checkSlideType(slide: Slide): Slide {
        if (slide.type === ContentEntityType.slide) {
            return slide;
        }

        return { ...slide, type: ContentEntityType.slide };
    }

    private serializeLegacyContent(content: ContentElement[][][]): Row[] {
        return content.map((row) => ({
            verticalAlign: 'center',
            columns: row,
        }));
    }
}
