import { Injectable } from '@angular/core';
import { MediaController } from '@app/core/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { ErrorType } from '@app/error/models';
import { MediaException } from '@app/onboarding/models';
import { ErrorService } from '@app/core/services/error.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class MediaService {
    audio: MediaController;

    video: MediaController;

    devices$: Observable<MediaDeviceInfo[]>;

    mediaAccess$: Observable<boolean>;

    private readonly devices$$ = new BehaviorSubject<MediaDeviceInfo[]>(null);

    private readonly mediaAccess$$ = new BehaviorSubject<boolean>(false);

    constructor(private readonly errorService: ErrorService) {
        this.devices$ = this.devices$$.asObservable();
        this.mediaAccess$ = this.mediaAccess$$.asObservable();
    }

    async init() {
        await this.checkAccessToDevice({
            audio: true,
            video: true,
        });
        this.mediaAccess$$.next(true);
        await this.listenForDevices();
        this.audio = new MediaController(this.devices$, 'audio');
        this.video = new MediaController(this.devices$, 'video');
    }

    /**
     * Should be wrapped in try/catch, in case of success returns nothing
     */
    async checkAccessToDevice(
        constraints: MediaStreamConstraints
    ): Promise<void> {
        const stream = await this.getMediaStream(constraints);

        if (!stream) {
            return;
        }

        stream.getTracks().forEach((track) => track.stop());
    }

    async getMediaStream(
        constraints: MediaStreamConstraints
    ): Promise<MediaStream | null> {
        let stream: MediaStream | null = null;

        try {
            stream = await navigator.mediaDevices.getUserMedia(constraints);
        } catch (e) {
            // unknown error, we don't know how to handle it YET
            if (!(e instanceof DOMException)) {
                this.errorService.handleFatalError(ErrorType.unknown, e);
            }

            // if user doesn't allow access to media, we should navigate him to error page
            if (e.name === MediaException.notAllowed) {
                this.errorService.handleFatalError(ErrorType.mediaAccess, e);
            }
        }

        return stream;
    }

    /*
     * It should be called after leaving onboarding page, as onboarding page
     * has its own mechanism implemented inside device selectors
     */
    startListenDevicesChange() {
        this.initDeviceChangeListener(this.audio);
        this.initDeviceChangeListener(this.video);
    }

    private async listenForDevices() {
        await this.updateDevices();

        navigator.mediaDevices.ondevicechange = () => this.updateDevices();
    }

    private async updateDevices() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        this.devices$$.next(devices);
    }

    private initDeviceChangeListener(mediaController: MediaController) {
        mediaController.devices$
            .pipe(untilDestroyed(this))
            .subscribe((devices) => {
                const { deviceId } = mediaController;

                if (!devices.find((device) => device.deviceId === deviceId)) {
                    const newDeviceId = devices.length
                        ? devices[0].deviceId
                        : null;
                    mediaController.setDeviceId(newDeviceId);
                }
            });
    }
}
