import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SpinnerComponent } from '@app/shared-modules/spinner/components/spinner/spinner.component';
import { Containers } from '@app/shared-modules/spinner/containers';
import { Components } from '@app/shared-modules/spinner/components';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [Containers, Components],
    imports: [CommonModule, MatButtonModule, TranslateModule],
    exports: [Containers, SpinnerComponent],
})
export class SpinnerModule {}
