import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { Components } from './components';

@NgModule({
    declarations: [Components],
    imports: [CommonModule, MatCardModule],
    exports: [Components],
})
export class CardModule {}
