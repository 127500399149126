import { Component } from '@angular/core';

import { VIEW_MODES, ViewModeType } from '@libs/models';
import { Store } from '@ngrx/store';
import * as fromCore from '@app/core/store';
import { SettingsActions } from '@app/core/store/actions';

@Component({
    selector: 'app-admin-navigation',
    templateUrl: './admin-navigation.component.html',
    styleUrls: ['./admin-navigation.component.scss'],
})
export class AdminNavigationComponent {
    readonly viewModes = VIEW_MODES;

    currentMode$ = this.store.select(fromCore.selectMode);

    isAdmin$ = this.store.select(fromCore.selectIsAdmin);

    constructor(private readonly store: Store<fromCore.CoreState>) {}

    changeViewMode(mode: string): void {
        this.store.dispatch(
            // selector output type is `string`, that's why I did `as ViewModeType`
            SettingsActions.setMode({ mode: mode as ViewModeType })
        );
    }
}
