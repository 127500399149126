import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TimelineModule } from '@app/shared-modules/timeline/timeline.module';
import { ImageModule } from '@app/shared-modules/image/image.module';
import { Directives } from '@app/shared-modules/content-view/directives';
import { TrustUrlModule } from '@app/shared-modules/trust-url/trust-url.module';
import { Components } from '@app/shared-modules/content-view/components';
import { ContentViewServicesModule } from '@app/shared-modules/content-view/content-view-services.module';
import { TranslateModule } from '@ngx-translate/core';
import { TimerModule } from '@app/shared-modules/timer/timer.module';

@NgModule({
    declarations: [Components, Directives],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        TrustUrlModule,
        ContentViewServicesModule,
        MatTooltipModule,
        MatIconModule,
        MatSliderModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        TimerModule,
        MatExpansionModule,
        ImageModule,
        TimelineModule,
    ],
    exports: [Components],
})
export class ContentViewModule {}
